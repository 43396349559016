import { useQuery } from 'react-admin'

const useLocation = (locationId) => {
	const { data } = useQuery({
		type: 'getOne',
		resource: 'locations',
		payload: {
			id: locationId,
		},
	})
	return data
}

export default useLocation
