import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Card, createMuiTheme, makeStyles } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { useCheckAuth } from 'ra-core'
import defaultTheme from '../theme'
import Notification from './Notification'
import LoginForm from './LoginForm'

// TS: (theme: Theme)
const useStyles = makeStyles(
	(theme) => ({
		main: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh',
			height: '1px',
			alignItems: 'center',
			justifyContent: 'flex-start',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundImage: 'url("locorum-bg.jpg")',
		},
		card: {
			minWidth: 300,
			marginTop: '6em',
			opacity: 0.85,
		},
		logo: {
			margin: '1em',
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: '#00A1D4',
			padding: '2.5em',
			borderRadius: '.5em',
		},
		icon: {
			width: '100%',
		},
	}),
	{ name: 'RaLogin' },
)

const Login = (props) => {
	const {
		theme,
		classes: classesOverride,
		className,
		children,
		staticContext,
		backgroundImage,
		...rest
	} = props
	const containerRef = useRef()
	const classes = useStyles(props)
	const muiTheme = useMemo(() => createMuiTheme(theme), [theme])
	let backgroundImageLoaded = false
	const checkAuth = useCheckAuth()
	const history = useHistory()

	useEffect(() => {
		checkAuth({}, false)
			.then(() => {
				// already authenticated, redirect to the home page
				history.push('/')
			})
			.catch(() => {
				// not authenticated, stay on the login page
			})
	}, [checkAuth, history])

	const updateBackgroundImage = () => {
		if (!backgroundImageLoaded && containerRef.current) {
			containerRef.current.style.backgroundImage = `url(${backgroundImage})`
			backgroundImageLoaded = true
		}
	}

	// Load background image asynchronously to speed up time to interactive
	const lazyLoadBackgroundImage = () => {
		if (backgroundImage) {
			const img = new Image()
			img.onload = updateBackgroundImage
			img.src = backgroundImage
		}
	}

	useEffect(() => {
		if (!backgroundImageLoaded) {
			lazyLoadBackgroundImage()
		}
	})

	return (
		<ThemeProvider theme={muiTheme}>
			<div
				className={classnames(classes.main, className)}
				{...rest}
				ref={containerRef}
			>
				<Card className={classes.card}>
					<div className={classes.logo}>
						<img className={classes.icon} src="/loco.svg" alt="Locorum" />
					</div>
					{children}
				</Card>
				<Notification />
			</div>
		</ThemeProvider>
	)
}

Login.propTypes = {
	backgroundImage: PropTypes.string,
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	theme: PropTypes.object,
	staticContext: PropTypes.object,
}

Login.defaultProps = {
	theme: defaultTheme,
	children: <LoginForm />,
}

export default Login
