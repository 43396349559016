import React from 'react'
import { Button, InputAdornment } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import fiLocale from 'date-fns/locale/fi'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '../components/overrides'

const useStyles = makeStyles({
	actionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: 20,
	},
	printButton: {
		maxWidth: 200,
		marginRight: '2em',
	},
	datePicker: {
		maxWidth: 200,
		marginRight: '0.5em',
	},
	reportDetailContainer: {
		marginBottom: '1em',
		display: 'flex',
		justifyContent: 'space-between',
	},
	monthYearContainer: {
		display: 'flex',
	},
	month: {
		paddingRight: '0.5em',
	},
	actionsWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
})

const PrintReportButton = ({ handlePrint, isDisabled }) => {
	const classes = useStyles()
	return (
		<Button
			className={classes.printButton}
			onClick={handlePrint}
			color="primary"
			disabled={isDisabled}
		>
			Tulosta Raportti
		</Button>
	)
}

const UserReportPanel = ({ setMonthMode, monthMode, ...props }) => {
	const classes = useStyles()
	return (
		<div className={classes.actionContainer}>
			<>
				<Typography variant="h6"> Tulokset </Typography>
			</>
			<div className={classes.actionsWrapper}>
				<PrintReportButton
					handlePrint={props.handlePrint}
					isDisabled={
						props.data && Object.keys(props.data).length !== 0 ? false : true
					}
				/>

				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
					<DatePicker
						className={classes.datePicker}
						variant="inline"
						openTo="year"
						views={monthMode ? ['year', 'month'] : ['year']}
						helperText={
							monthMode
								? 'Valitse vuosi ja kuukausi'
								: 'HUOM! Ei sisällä kuluvaa kuukautta'
						}
						value={props.selectedTime}
						onChange={(time) => {
							props.setSelectedTime(time)
						}}
						autoOk={true}
						InputProps={{
							style: { padding: '10px 0' },
							endAdornment: (
								<InputAdornment>
									<Button
										onClick={() => setMonthMode(!monthMode)}
										variant="outlined"
										size="small"
									>
										<Typography> {monthMode ? 'KK' : 'Vuosi'} </Typography>
									</Button>
								</InputAdornment>
							),
						}}
					/>
				</MuiPickersUtilsProvider>
			</div>
		</div>
	)
}

export default UserReportPanel
