import React from 'react'
import { PasswordInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { validatePassword } from '../../utils/FormValidators'

const useStyles = makeStyles({
	passwordInput: {},
})

export default (props) => {
	const classes = useStyles()

	return (
		<PasswordInput
			{...props}
			className={classes.passwordInput}
			variant="outlined"
			validate={validatePassword}
			fullWidth
		/>
	)
}
