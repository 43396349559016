import * as React from 'react'
import {
	Datagrid,
	ReferenceField,
	Filter,
	SingleFieldList,
	ChipField,
} from 'react-admin'
import {
	TextInput,
	List,
	TextField,
	EmailField,
	ArrayField,
} from '../components/overrides'
import AgentFullNameField from '../components/AgentFullNameField'
import { ListActions } from '../components/overrides'

const AgentList = ({ filtering, ...raProps }) => {
	return (
		<List
			{...raProps}
			title="Edustajat"
			bulkActionButtons={false}
			actions={<ListActions />}
			filters={
				<Filter>
					<TextInput label="Etsi nimellä" source="fullNameSearch" alwaysOn />
				</Filter>
			}
		>
			<Datagrid rowClick="show">
				<AgentFullNameField label="Edustaja" source="lastName" extraPadding />
				<EmailField label="Sähköposti" source="email" />
				<ReferenceField label="Alue" source="zoneId" reference="zones" perPage={100}>
					<TextField source="name" />
				</ReferenceField>
				<ArrayField label="Tapaamispaikat" source="locations">
					<SingleFieldList linkType="">
						<ChipField source="name" />
					</SingleFieldList>
				</ArrayField>
			</Datagrid>
		</List>
	)
}

export default AgentList
