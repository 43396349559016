import React from 'react'
import { IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import CustomTitle from '../../components/CustomTitle'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	titleBar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	iconButton: {
		boxSizing: 'content-box',
		height: '1rem',
	},
})

const TitleBar = (props) => {
	const classes = useStyles()
	return (
		<span className={classes.titleBar}>
			<CustomTitle text={props.titleText} />
			<IconButton
				className={classes.iconButton}
				onClick={() => props.setActive(false)}
			>
				<ClearIcon />
			</IconButton>
		</span>
	)
}

export default TitleBar
