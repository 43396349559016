import Axios from 'axios'

const GetCancelBookingDetails = (id, token, setBooking) => {
	Axios.get(
		`${process.env.REACT_APP_API_URL}/bookings/${id}/details?token=${token}`,
	)
		.then(({ data }) => {
			data.cancelDetails = {
				cancelType: null,
				cancelReason: null,
				reasonDetails: null,
				otherDetails: null,
			}
			setBooking(data)
		})
		.catch((error) => {})
}

export default GetCancelBookingDetails
