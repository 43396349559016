import { useUpdate } from 'react-admin'

const RemoveBookingAgent = (record) => {
	const [unSelect, { loading }] = useUpdate(
		'bookings',
		record.id,
		{ ...record, agentId: null },
		record,
	)
	return [unSelect, loading]
}

export default RemoveBookingAgent
