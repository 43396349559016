import React from 'react'
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContentText,
} from '@material-ui/core'
import { DialogContent, Edit } from '../../components/overrides'
import { makeStyles } from '@material-ui/core/styles'
import { SetBookingArchived } from '../../effects'

const useStyles = makeStyles({
	archiveActionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
})

const ArchiveButton = (props) => {
	const [archive, loading] = SetBookingArchived(props.record)
	return (
		<Button
			color="primary"
			variant="contained"
			onClick={() => {
				archive()
				props.setArchiveDialogActive(false)
			}}
			disabled={loading}
		>
			Arkistoi buukkaus
		</Button>
	)
}

const ArchiveActions = (props) => {
	const classes = useStyles()
	return (
		<div className={classes.archiveActionsContainer}>
			<ArchiveButton
				{...props}
				setArchiveDialogActive={props.setArchiveDialogActive}
			/>
			<Button
				color="secondary"
				onClick={() => props.setArchiveDialogActive(false)}
			>
				Älä arkistoi
			</Button>
		</div>
	)
}

const ArchiveBooking = (props) => {
	const { setArchiveDialogActive, archiveDialogActive, ...raProps } = props
	return (
		<Dialog
			anchor="right"
			open={props.archiveDialogActive}
			onClose={() => setArchiveDialogActive(false)}
		>
			<DialogContent>
				<DialogTitle>Buukkauksen arkistointi</DialogTitle>
				<DialogContentText>
					Haluatko varmasti arkistoida buukkauksen?
				</DialogContentText>
				<Edit {...raProps} title=" " actions={null} component="div">
					<ArchiveActions setArchiveDialogActive={setArchiveDialogActive} />
				</Edit>
			</DialogContent>
		</Dialog>
	)
}

export default ArchiveBooking
