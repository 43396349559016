import React from 'react'
import {
	Edit,
} from '../components/overrides'
import BookingForm from './BookingForm'

const BookingEdit = (props) => {
	return (
		<Edit {...props} title="Muokkaa buukkausta">
			<BookingForm title="Muokkaa buukkausta" />
		</Edit>
	)
}

export default BookingEdit
