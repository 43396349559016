import React from 'react'
import { SimpleShowLayout, Datagrid, ReferenceManyField } from 'react-admin'
import { Typography } from '@material-ui/core'
import { TextField } from '../components/overrides'
import { AddLocationButton } from './ZoneAddButtons'
import { makeStyles } from '@material-ui/core/styles'
import SectionDivider from '../components/SectionDivider'

const useStyles = makeStyles({
	title: {
		height: 50,
	},
})

const LayoutTitle = () => {
	const classes = useStyles()
	return (
		<Typography className={classes.title} variant="h6">
			Huomioita tapaamistyypeistä
		</Typography>
	)
}

export default (props) => {
	return (
		<SimpleShowLayout {...props}>
			<LayoutTitle />
			<TextField
				label="Konttori"
				addLabel={true}
				source="locationConsiderations"
			/>
			<TextField label="Puhelin" addLabel={true} source="phoneConsiderations" />
			<TextField
				label="Kotikäynnit"
				addLabel={true}
				source="homeConsiderations"
			/>
			<TextField
				label="Yritys"
				addLabel={true}
				source="companyConsiderations"
			/>
			<TextField label="Verkko" addLabel={true} source="webConsiderations" />
			<TextField label="Maatila" addLabel={true} source="farmConsiderations" />
			<SectionDivider>
				Tapaamispaikat
			</SectionDivider>
			<ReferenceManyField
				reference="locations"
				target="zoneId"
				addLabel={false}
				sort={{ field: 'name', order: 'DESC' }}
			>
				<Datagrid rowClick="show">
					<TextField label="Nimi" source="name" />
				</Datagrid>
			</ReferenceManyField>
			<AddLocationButton />
		</SimpleShowLayout>
	)
}
