import BookingEdit from './BookingEdit'
import BookingShow from './BookingShow'
import BookingCreate from './BookingCreate'
import BookingList from './BookingList'

export default {
	create: BookingCreate,
	list: BookingList,
	show: BookingShow,
	edit: BookingEdit,
}
