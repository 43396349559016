var crudRequest = require('@nestjsx/crud-request')
var reactAdmin = require('react-admin')

const OppositeLetterCase = (letter) => {
	// somehow regex [a-ö] includes upper cases of ä-ö, so need to add them separately
	return /[a-zäåö]/.test(letter) ? letter.toUpperCase() : letter.toLowerCase()
}

export default (apiUrl, httpClient = reactAdmin.fetchUtils.fetchJson) => {
	const composeFilter = (paramsFilter) => {
		if (
			paramsFilter === '' ||
			(typeof paramsFilter.q !== 'undefined' && paramsFilter.q === '')
		) {
			paramsFilter = {}
		}
		const flatFilter = reactAdmin.fetchUtils.flattenObject(paramsFilter)
		let nameSearch = false
		let nameSearchValue
		let fullNameSearch = false
		let splitSearch
		let filter = Object.keys(flatFilter).map((key) => {
			const splitKey = key.split('||')
			let ops
			let field

			if (splitKey[0] === 'name') {
				nameSearch = true
				nameSearchValue = flatFilter[key]
				return null
			} else if (splitKey[0] === 'fullNameSearch') {
				fullNameSearch = true
				splitSearch = flatFilter[key].split(' ')
				return null
			} else {
				ops = Array.isArray(flatFilter[key]) ? '$in' : '$eq'
				field = splitKey[0]
			}
			if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
				field = field.split(/\.(.+)/)[1]
			}
			if (
				splitKey[0] === 'createdOn' ||
				splitKey[0] === 'appointmentStartsAt'
			) {
				const dayMode = flatFilter[key].match(/-/g || []).length > 1
				const dateStart = new Date(flatFilter[key])
				const dateEnd = dayMode
					? new Date(flatFilter[key])
					: (() => {
						const startCopy = new Date(flatFilter[key])
						startCopy.setMonth(startCopy.getMonth() + 1)
						return startCopy
					})()
				dateEnd.setDate(dateEnd.getDate() + 1)
				flatFilter[key] = `${dateStart.toISOString()},${dateEnd.toISOString()}`
				ops = `$between`
			}
			if (splitKey[0] === 'locations') {
				ops = '$cont'
			}

			if (splitKey[0] === 'createdBy' && typeof flatFilter[key] === 'object') {
				field = 'createdBy.id'
				flatFilter[key] = 0
			}

			if (splitKey[0] === "zones") {
				field = "zoneId"
			}

			return {
				field,
				operator: ops,
				value: flatFilter[key],
			}
		})
		// for search with name (just "name" field)
		if (nameSearch) {
			filter = []
			filter.push({
				field: 'name',
				operator: '$cont',
				value: nameSearchValue,
			})
			// if first char lower, change to upper and vice versa
			// this because search is case sensitive
			let valueOtherCase =
				OppositeLetterCase(nameSearchValue.charAt(0)) + nameSearchValue.slice(1)
			filter.push({
				field: 'name',
				operator: '$cont',
				value: valueOtherCase,
			})
		}
		// separate filters for lower and uppercases, and words separated by space
		// for search from "firstName" and "lastName"
		else if (fullNameSearch) {
			filter = []
			const fields = {
				0: 'firstName',
				1: 'lastName',
			}
			for (let word = 0; word < 2; word++) {
				if (splitSearch[word]) {
					// if first char lower, change to upper and vice versa
					// this because search is case sensitive
					let valueOtherCase =
						OppositeLetterCase(splitSearch[word].charAt(0)) +
						splitSearch[word].slice(1)
					for (let field = 0; field < 2; field++) {
						filter.push({
							field: fields[field],
							operator: '$cont',
							value: splitSearch[word],
						})
						filter.push({
							field: fields[field],
							operator: '$cont',
							value: valueOtherCase,
						})
					}
				}
			}
		}
		return filter
	}

	const composeSearch = (value) => {
		const fields = [
			'customer.gsm',
			'customer.name',
			'customer.address',
			'customer.email',
			'customer.businessId',
			'notes',
			'coordinatorEntries',
		]
		const operator = '$contL'

		return fields.map((field) => ({
			field,
			operator,
			value,
		}))
	}

	const convertDataRequestToHTTP = (type, resource, params) => {
		let url = ''
		const options = {}
		const searching = params.filter?.q
		switch (type) {
			case reactAdmin.GET_LIST: {
				const { page, perPage } = params.pagination
				// or for agent search within firstName and lastName
				let queryParameter =
					params.filter.fullNameSearch || params.filter.name || searching
						? 'or'
						: 'filter'
				const query = crudRequest.RequestQueryBuilder.create({
					[queryParameter]: searching
						? composeSearch(params.filter.q)
						: composeFilter(params.filter),
				})

					.setLimit(perPage)
					.setPage(page)
					.sortBy(params.sort)
					.setOffset((page - 1) * perPage)
					.query()

				url = `${apiUrl}/${resource}?${query}`
				break
			}
			case reactAdmin.GET_ONE: {
				url = `${apiUrl}/${resource}/${params.id}`

				break
			}
			case reactAdmin.GET_MANY: {
				const query = crudRequest.RequestQueryBuilder.create()
					.setFilter({
						field: 'id',
						operator: crudRequest.CondOperator.IN,
						value: `${params.ids}`,
					})
					.query()

				url = `${apiUrl}/${resource}?${query}`

				break
			}
			case reactAdmin.GET_MANY_REFERENCE: {
				const { page, perPage } = params.pagination
				const filter = composeFilter(params.filter)

				filter.push({
					field: params.target,
					operator: crudRequest.CondOperator.EQUALS,
					value: params.id,
				})

				const query = crudRequest.RequestQueryBuilder.create({
					filter,
				})
					.sortBy(params.sort)
					.setLimit(perPage)
					.setOffset((page - 1) * perPage)
					.query()

				url = `${apiUrl}/${resource}?${query}`

				break
			}
			case reactAdmin.UPDATE: {
				url = `${apiUrl}/${resource}/${params.id}`
				options.method = 'PATCH'
				options.body = JSON.stringify(params.data)
				break
			}
			case reactAdmin.CREATE: {
				url = `${apiUrl}/${resource}`
				options.method = 'POST'
				options.body = JSON.stringify(params.data)
				break
			}
			case reactAdmin.DELETE: {
				url = `${apiUrl}/${resource}/${params.id}`
				options.method = 'DELETE'
				break
			}
			default:
				throw new Error(`Unsupported fetch action type ${type}`)
		}
		return { url, options }
	}

	const convertHTTPResponse = (response, type, resource, params) => {
		const { json } = response
		switch (type) {
			case reactAdmin.GET_LIST:
			case reactAdmin.GET_MANY_REFERENCE:
				return {
					data: json.data,
					total: json.total,
				}
			case reactAdmin.CREATE:
				return { data: { ...params.data, id: json.id } }
			default:
				return { data: json }
		}
	}

	return (type, resource, params) => {
		if (type === reactAdmin.UPDATE_MANY) {
			return Promise.all(
				params.ids.map((id) =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'PUT',
						body: JSON.stringify(params.data),
					}),
				),
			).then((responses) => ({
				data: responses.map((response) => response.json),
			}))
		}
		if (type === reactAdmin.DELETE_MANY) {
			return Promise.all(
				params.ids.map((id) =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'DELETE',
					}),
				),
			).then((responses) => ({
				data: responses.map((response) => response.json),
			}))
		}

		const { url, options } = convertDataRequestToHTTP(type, resource, params)
		return httpClient(url, options).then((response) =>
			convertHTTPResponse(response, type, resource, params),
		)
	}
}
