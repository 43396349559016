import * as React from 'react'
import { Button, Link } from 'react-admin'
//import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	button: {
		marginTop: '10px',
		marginBottom: '10px',
	},
})

const AddLocationButton = ({ record }) => {
	const classes = useStyles()
	return (
		<Link
			to={{
				pathname: '/locations/create',
				state: { record: { zoneId: record.id } },
			}}
		>
			<Button className={classes.button} label="Lisää tapaamispaikka"></Button>
		</Link>
	)
}

const AddAgentButton = ({ record }) => {
	const classes = useStyles()
	return (
		<Link
			to={{
				pathname: '/agents/create',
				state: { record: { zoneId: record.id } },
			}}
		>
			<Button className={classes.button} label="Lisää edustaja"></Button>
		</Link>
	)
}

export { AddAgentButton, AddLocationButton }
