import axios from 'axios'

// agents?month=${month}&year=${year}
const GetZoneAgents = (zoneId, setAgents, selectedTime, additionalFilter) => {
	const month = selectedTime && selectedTime.getMonth() + 1
	const year = selectedTime && selectedTime.getFullYear()
	const path =
		month && year
			? `${
					process.env.REACT_APP_API_URL
			  }/agents?month=${month}&year=${year}&filter[0]=zoneId||$eq||${zoneId}${
					additionalFilter ? additionalFilter : ''
			  }`
			: `${
					process.env.REACT_APP_API_URL
			  }/agents?filter[0]=zoneId||$eq||${zoneId}${
					additionalFilter ? additionalFilter : ''
			  }`

	const token = localStorage.getItem('jwt')
	axios
		.get(path, {
			headers: { Authorization: `Bearer: ${token}` },
		})
		.then((response) => {
			setAgents(response.data)
		})
		.catch((error) => {})
}

export default GetZoneAgents
