import React, { useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import CustomTitle from '../components/CustomTitle'
import {
	Button,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormHelperText,
	FormControl,
	TextField,
	makeStyles,
	Typography,
	Paper,
} from '@material-ui/core'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { GetCancelBookingDetails, SetCancelBookingDetails } from '../effects'
import SectionDivider from '../components/SectionDivider'
import Loading from '../components/Loading'
import fieldTranslations from '../utils/fieldTranslations'

const useStyles = makeStyles({
	form: {
		display: 'flex',
		flexDirection: 'column',
	},
	label: {
		marginTop: '8px',
		color: 'rgba(0,0,0,0.54)',
	},
	field: {
		marginBottom: '4px',
	},
	button: {
		margin: '2em auto 0 0',
	},
	infoTypography: {
		color: 'red',
	},
	paper: {
		maxWidth: 1440,
		width: '100%',
		padding: '1em',
		boxSizing: 'border-box',
	},
})

const meetingTypeLabels = {
	WEB: 'Verkko',
	PHONE: 'Puhelin',
	LOCATION: 'Tapaamispaikka',
	CUSTOMERS_LOCATION: 'Asiakkaan luona',
}

const CancelBookingForm = (props) => {
	const classes = useStyles()
	const [success, setSuccess] = useState(false)
	const [booking, setBooking] = useState()

	const { id, token } = props.match.params

	useEffect(() => {
		GetCancelBookingDetails(id, token, setBooking)
	}, [id, token])

	if (!booking) {
		return <Loading />
	}

	return (
		<>
			{success ? (
				<Paper className={classes.paper}>
					<CustomTitle text="Peruutus onnistui" />
					<Typography>Voit sulkea tämän välilehden</Typography>
				</Paper>
			) : (
				<Paper className={classes.paper}>
					<Form
						onSubmit={(values) => {
							SetCancelBookingDetails(
								id,
								token,
								values.cancelDetails,
								setSuccess,
							)
						}}
						initialValues={booking}
						render={({ handleSubmit, ...formProps }) => {
							const currentMonth = new Date().getMonth()
							const appointmentMonth = new Date(
								booking.appointmentStartsAt,
							).getMonth()

							return (
								<>
									{formProps.values.status === 'CANCELED' ||
									(formProps.values.status === 'COMPLETED' &&
										appointmentMonth !== currentMonth) ||
									formProps.values.status === 'CANCELED_RETRIAL' ? (
										<Typography variant="h5" className={classes.infoTypography}>
											{formProps.values.status === 'CANCELED_RETRIAL'
												? 'Linkki on vanhentunut.'
												: `Buukkaus on jo ${
														formProps.values.status === 'CANCELED'
															? 'peruutettu'
															: 'toteutunut'
												  }` +
												  '' +
												  `${
														formProps.values.archived ? ' ja arkistoitu.' : '.'
												  }`}
										</Typography>
									) : (
										<form className={classes.form} onSubmit={handleSubmit}>
											<CustomTitle text="Tapaamisen tiedot" />
											<Field
												name="appointmentStartsAt"
												render={(props) => {
													return (
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
															<DateTimePicker
																className={classes.field}
																ampm={false}
																format="dd.MM.yyyy HH:mm"
																{...props.input}
																size="small"
																disabled
															/>
														</MuiPickersUtilsProvider>
													)
												}}
											/>
											<LabelValue
												label="Tapaamistyyppi:"
												value={`${
													fieldTranslations.customerType[
														booking.customer.customerType
													]
												} - ${meetingTypeLabels[booking.meetingType]}`}
											/>

											<LabelValue label="Nimi:" value={booking.customer.name} />
											<LabelValue
												label="Osoite:"
												value={booking.customer.address}
											/>
											<LabelValue
												label="Puhelinnumero:"
												value={booking.customer.gsm}
											/>
											<LabelValue
												label="Syntymäaika:"
												value={new Date(
													booking.customer.birthday,
												).toLocaleDateString()}
											/>
											<LabelValue
												label="Talouden vakuutettavat henkilöt:"
												value={booking.amountToBeInsured}
											/>
											<LabelValue
												label="Missä yhtiössä vakuutuksia:"
												value={booking.insuranceCompanies?.join(', ')}
											/>
											<LabelValue
												label="Mitä vakuutuksia kilpailijalla:"
												value={booking.insurancesFromCompetitors?.join(', ')}
											/>
											<LabelValue
												label="S-etukortti:"
												value={fieldTranslations.bonusCard[booking.bonusCard]}
											/>
											<LabelValue
												label="Laskutustyyppi:"
												value={
													fieldTranslations.invoiceType[booking.invoiceType]
												}
											/>
											<SectionDivider style={{ margin: '1rem 0' }} />
											<CustomTitle text="Peruuta buukkaus" />
											<Typography className={classes.label}>
												Siirrettävä vai peruttava tapaaminen
											</Typography>
											<Field
												name="cancelDetails.cancelType"
												type="radio"
												validate={(value) =>
													value ? undefined : 'Vaadittu kenttä'
												}
												render={(props) => {
													return (
														<FormControl
															className={classes.field}
															component="fieldset"
														>
															<RadioGroup
																aria-label="cancelType"
																{...props.input}
																row
															>
																<FormControlLabel
																	value="POSTPONE"
																	control={<Radio />}
																	label="Siirrettävä tapaaminen"
																/>
																<FormControlLabel
																	value="CANCEL"
																	control={<Radio />}
																	label="Peruutettu / ei saapunut tapaamiseen"
																/>
															</RadioGroup>
															{props.meta.touched && (
																<FormHelperText
																	className={classes.infoTypography}
																>
																	{props.meta.error}
																</FormHelperText>
															)}
														</FormControl>
													)
												}}
											/>
											<Typography className={classes.label}>
												Siirron / peruuntumisen syy
											</Typography>
											<Field
												name="cancelDetails.cancelReason"
												type="radio"
												validate={(value) =>
													value ? undefined : 'Vaadittu kenttä'
												}
												render={(props) => {
													return (
														<FormControl
															className={classes.field}
															component="fieldset"
														>
															<RadioGroup
																aria-label="cancelReason"
																{...props.input}
																row
															>
																<FormControlLabel
																	value="NO_NOTICE"
																	control={<Radio />}
																	label="Ei saapunut paikalle (ei ilmoitusta)"
																/>
																<FormControlLabel
																	value="UNANSWERED_CALL"
																	control={<Radio />}
																	label="Ei vastannut puheluun"
																/>
																<FormControlLabel
																	value="CHANGED_MIND"
																	control={<Radio />}
																	label="Mieli muuttunut"
																/>
																<FormControlLabel
																	value="HINDRANCE"
																	control={<Radio />}
																	label="Työeste / meno"
																/>
																<FormControlLabel
																	value="SICKNESS"
																	control={<Radio />}
																	label="Sairastuminen"
																/>
																<FormControlLabel
																	value="CREDIT"
																	control={<Radio />}
																	label="Luottotiedot"
																/>
																<FormControlLabel
																	value="OTHER"
																	control={<Radio />}
																	label="Muu"
																/>
															</RadioGroup>
															{props.meta.touched && (
																<FormHelperText
																	className={classes.infoTypography}
																>
																	{props.meta.error}
																</FormHelperText>
															)}
														</FormControl>
													)
												}}
											/>
											{formProps.values.cancelDetails &&
											formProps.values.cancelDetails.cancelReason ===
												'OTHER' ? (
												<>
													<Typography className={classes.label}>
														Mikä syy
													</Typography>
													<Field
														name="cancelDetails.reasonDetails"
														validate={(value, allValues) => {
															return value &&
																value !== '' &&
																allValues.cancelDetails.cancelReason === 'OTHER'
																? undefined
																: 'Vaadittu, jos syy on "Muu".'
														}}
														render={(props) => (
															<TextField
																className={classes.field}
																{...props.input}
																variant="outlined"
																size="small"
																error={
																	!!(props.meta.touched && props.meta.error)
																}
																helperText={
																	props.meta.touched && props.meta.error
																}
															/>
														)}
													/>
												</>
											) : null}
											<Typography className={classes.label}>
												Lisätietoja
											</Typography>
											<Field
												name="cancelDetails.otherDetails"
												render={(props) => (
													<TextField
														className={classes.field}
														{...props.input}
														variant="outlined"
														size="small"
														rows={3}
													/>
												)}
											/>

											<Button
												className={classes.button}
												type="submit"
												variant="contained"
												color="primary"
												disabled={formProps.values.status === 'CANCELED'}
											>
												Peruuta buukkaus
											</Button>
										</form>
									)}
								</>
							)
						}}
					/>
				</Paper>
			)}
		</>
	)
}

const LabelValue = ({ label, value }) => (
	<div style={{ display: 'flex', margin: '4px 0' }}>
		<Typography style={{ fontWeight: 'bold', marginRight: '1rem' }}>
			{label}
		</Typography>
		<Typography>{value}</Typography>
	</div>
)

export default CancelBookingForm
