import React from 'react'
import { SaveButton, useRefresh, FormDataConsumer } from 'react-admin'
import { Drawer } from '@material-ui/core'
import {
	TextInput,
	SimpleForm,
	RadioButtonGroupInput,
	Edit,
	Toolbar,
} from '../../components/overrides'
import TitleBar from '../bookingComponents/TitleBar'
import {
	validateRadioButton,
	validateCreditsInOrder,
} from '../../utils/FormValidators'
import { makeStyles } from '@material-ui/core/styles'
import { mapTranslationsToArray } from '../../utils/fieldTranslations'

const CheckCreditsEdit = (props) => {
	const classes = useStyles()
	const {
		setCreditsFormActive,
		creditFormActive,
		setAgentFormActive,
		...raProps
	} = props
	const refresh = useRefresh()

	const handleSuccess = (data) => {
		setCreditsFormActive(false)
		setAgentFormActive(true)
		refresh()
	}

	return (
		<Drawer
			classes={{
				paper: classes.drawer,
			}}
			className={classes.drawer}
			anchor="right"
			open={creditFormActive}
			onClose={() => setCreditsFormActive(false)}
		>
			<Edit
				{...raProps}
				actions={null}
				onSuccess={handleSuccess}
				title=" "
				undoable={false}
			>
				<SimpleForm toolbar={<CheckCreditsToolBar />}>
					<TitleBar
						titleText="Asiakastiedot"
						setActive={setCreditsFormActive}
					/>
					<FormDataConsumer>
						{({ formData }) => (
							<>
								{formData.creditCheckAllowed === true ? (
									<RadioButtonGroupInput
										label="Luottotiedot tarkistettu"
										source="creditsChecked"
										validate={validateRadioButton}
										choices={mapTranslationsToArray('creditsChecked')}
									/>
								) : (
									<RadioButtonGroupInput
										label="Luottotietojen tarkistus"
										style={{ color: 'red' }}
										source="creditsChecked"
										choices={[
											{
												id: 'NOT_ALLOWED',
												name: 'Ei lupaa tarkastaa luottotietoja',
											},
										]}
										validate={validateRadioButton}
									/>
								)}

								{formData.creditsChecked?.[0] === 'YES' ? (
									<RadioButtonGroupInput
										label="Luottotiedot kunnossa"
										source="creditsInOrder"
										validate={validateCreditsInOrder}
										choices={mapTranslationsToArray('creditsInOrder')}
									/>
								) : null}
								{formData.customer?.customerType === 'PRIVATE' ? (
									<RadioButtonGroupInput
										label="Laskutustyyppi"
										source="invoiceType"
										validate={validateRadioButton}
										choices={mapTranslationsToArray('invoiceType')}
									/>
								) : null}
							</>
						)}
					</FormDataConsumer>
					<TextInput
						label="Vapaa sana"
						helperText="Mitä vakuutuksia tällä hetkellä ja mistä"
						multiline
						rows={5}
						source="notes"
					/>
				</SimpleForm>
			</Edit>
		</Drawer>
	)
}

const useStyles = makeStyles({
	drawer: {
		width: '50%',
	},
})

const CheckCreditsToolBar = (props) => {
	return (
		<Toolbar {...props}>
			<SaveButton />
		</Toolbar>
	)
}

export default CheckCreditsEdit
