import React from "react";
import { ReferenceInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    referenceInput: {
      width: "100%",
    },
  });
  
  export default (props) => {
    const classes = useStyles();
  
    return <ReferenceInput {...props} className={classes.referenceInput} variant="outlined" />;
  };