import React from 'react'
import { FunctionField } from './overrides'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	label: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '1rem',
		lineHeight: 1,
	},
	body: {
		padding: '8px 0px 4px 0px',
	},
	wrapper: {
		margin: '8px 0px 4px 0px',
	},
})

const AgentFullNameField = ({ showCustomLabel, extraPadding, ...props }) => {
	const classes = useStyles()

	return (
		<FunctionField
			{...props}
			render={() => (
				<div className={extraPadding ? classes.wrapper : null}>
					{showCustomLabel && (
						<Typography className={classes.label}>{props.label}</Typography>
					)}

					{props.record?.firstName ? (
						<Typography className={extraPadding ? classes.wrapper : null}>
							{props.record.firstName + ' ' + props.record.lastName}
						</Typography>
					) : (
						<Typography>Ei tietoja</Typography>
					)}
				</div>
			)}
		/>
	)
}

export default AgentFullNameField
