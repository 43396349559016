import React, { useState, useCallback } from 'react'
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { DialogContent } from '../../components/overrides'
import { useEffect } from 'react'
import postRetrial from '../../effects/PostRetrial'
import { useNotify } from 'react-admin'

const RetrialDialog = ({ open, retrialId, ...props }) => {
	const [retrialBookingId, setretrialBookingId] = useState()
	const notify = useNotify()

	const handleRetrial = useCallback(async () => {
		const id = await postRetrial(retrialId, notify)
		setretrialBookingId(id)
	}, [retrialId, notify])

	useEffect(() => {
		if (open) {
			handleRetrial()
		}
	}, [open, handleRetrial])

	return (
		<Dialog open={open} onClose={() => props.setRetrialDialogActive(false)}>
			<DialogContent>
				<DialogTitle>Buukkaus palautettu käsittelyyn!</DialogTitle>
				<DialogContentText>
					Haluatko siirtyä palautetun buukkauksen näkymään?
				</DialogContentText>
				<DialogActions>
					<Button
						component={Link}
						color="primary"
						variant="contained"
						to={{ pathname: `/bookings/${retrialBookingId}/show` }}
						onClick={() => props.setRetrialDialogActive(false)}
					>
						Siirry
					</Button>
					<Button
						color="secondary"
						onClick={() => props.setRetrialDialogActive(false)}
					>
						Pysy tällä sivulla
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}

export default RetrialDialog
