import React from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	radioButtonGroupInput: {
		width: '100%',
		'& .MuiFormGroup-root': {
			flexDirection: 'row',
		},
		'& .RaRadioButtonGroupInput-label-40': {
			transform: 'unset',
		}
	},
})

export default (props) => {
	const classes = useStyles()
	return (
		<RadioButtonGroupInput
			{...props}
			className={classes.radioButtonGroupInput}
			variant="outlined"
			options={{ row: false }}
		/>
	)
}
