import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from './overrides'

const useStyles = makeStyles({
	hr: {
		marginLeft: 0,
		marginRight: 0,
		width: '100%',
	},
})

const SectionDivider = ({ children, style }) => {
	const classes = useStyles()
	return (
		<div style={style}>
			<hr className={classes.hr} />
			<Typography variant="h6">{children}</Typography>
		</div>
	)
}

export default SectionDivider
