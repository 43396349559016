import React from 'react'
import { Create } from '../components/overrides'
import BookingForm from './BookingForm'

const BookingCreate = (props) => {
	return (
		<Create {...props} title="Lisää buukkaus">
			<BookingForm title="Lisää buukkaus" />
		</Create>
	)
}

export default BookingCreate
