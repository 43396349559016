import * as React from 'react'
import {
	TextField,
	Datagrid,
	EmailField,
	TabbedShowLayout,
	ReferenceField,
	ShowController,
	ShowView,
} from 'react-admin'
import InfoIcon from '@material-ui/icons/Info'
import UnhandledIcon from '@material-ui/icons/Assignment'
import { Tab, ReferenceManyField, ArrayField } from '../components/overrides'
import AgentFullNameField from '../components/AgentFullNameField'
import SectionDivider from '../components/SectionDivider'
import BookingList from '../bookings/BookingList'
import BusinessHoursField from './BusinessHoursField'

const LocationShow = (props) => {
	return (
		<ShowController {...props}>
			{(controllerProps) => (
				<ShowView
					{...props}
					{...controllerProps}
					title={controllerProps.record && controllerProps.record.name + ' '}
					aside={
						<BusinessHoursField style={{ marginLeft: '1rem', marginTop: 0 }} />
					}
				>
					<TabbedShowLayout>
						<Tab label="Tiedot" icon={<InfoIcon color="primary" />}>
							<TextField label="Nimi" source="name" />
							<ReferenceField
								label="Alue"
								source="zoneId"
								reference="zones"
								link="show"
								perPage={100}
							>
								<TextField source="name" />
							</ReferenceField>
							<SectionDivider>Edustajat</SectionDivider>
							<ArrayField source="agents" label="">
								<Datagrid rowClick="">
									<AgentFullNameField label="Edustaja" source="lastName" />
									<EmailField label="Sähköposti" source="email" />
								</Datagrid>
							</ArrayField>
						</Tab>
						<Tab
							icon={<UnhandledIcon color="primary" />}
							label="Buukkaukset"
							path="bookings"
							title="Tapaamispaikan buukkaukset"
						>
							<ReferenceManyField
								reference="bookings"
								target="locationId"
								addLabel={false}
							>
								<BookingList
									zoneFilter={
										controllerProps.record && {
											locationId: controllerProps.record.id,
										}
									}
								/>
							</ReferenceManyField>
						</Tab>
					</TabbedShowLayout>
				</ShowView>
			)}
		</ShowController>
	)
}

export default LocationShow
