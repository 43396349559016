import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import fieldTranslations from '../utils/fieldTranslations'

const useStyles = makeStyles({
	statusCircle: {
		height: '1rem',
		width: '1rem',
		display: 'block',
		borderRadius: '50%',
	},
	UNHANDLED: { backgroundColor: '#e6f48a' },
	CONFIRMED: { backgroundColor: '#9affae' },
	CANCELED: { backgroundColor: '#ff98a3' },
	UNHANDLED_RETRIAL: { backgroundColor: '#99bbff' },
	CANCELED_RETRIAL: { backgroundColor: '#cf4250' },
	ARCHIVED: { backgroundColor: '#b2b2b2' },
	COMPLETED: { backgroundColor: '#349948' },
})

const StatusIcon = (props) => {
	const classes = useStyles()
	const status =
		props.record && props.record.archived ? 'ARCHIVED' : props.record.status
	return (
		<Tooltip title={fieldTranslations.status[status]}>
			<div className={`${classes.statusCircle} ${classes[status]}`} />
		</Tooltip>
	)
}

export default StatusIcon
