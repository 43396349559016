import * as React from 'react'
import {
	TabbedShowLayout,
	ReferenceField,
	ReferenceManyField,
	SingleFieldList,
	Labeled,
	ShowController,
	ShowView,
} from 'react-admin'
import { Tab, TextField, ArrayField } from '../components/overrides'
import InfoIcon from '@material-ui/icons/Info'
import BookIcon from '@material-ui/icons/Book'
import SectionDivider from '../components/SectionDivider'
import AgentFullNameField from '../components/AgentFullNameField'
import { SinglePersonCard } from '../components/PersonReports'
import BookingList from '../bookings/BookingList'

const AgentShow = (props) => {
	return (
		<ShowController {...props}>
			{(controllerProps) => (
				<ShowView
					{...props}
					{...controllerProps}
					title={
						controllerProps.record
							? `${controllerProps.record.firstName} ${controllerProps.record.lastName}`
							: ' '
					}
				>
					<TabbedShowLayout>
						<Tab label="Tiedot" icon={<InfoIcon color="primary" />}>
							<AgentFullNameField label="Nimi" showCustomLabel extraPadding />
							<TextField label="Sähköposti" source="email" />
							<ReferenceField label="Alue" source="zoneId" reference="zones" perPage={100}>
								<TextField source="name" />
							</ReferenceField>
							<Labeled label="Tapaamispaikat">
								<ArrayField source="locations">
									<SingleFieldList linkType="">
										<TextField source="name" />
									</SingleFieldList>
								</ArrayField>
							</Labeled>

							<SectionDivider>
								Edustajan kuukauden tilaukset (buukkaukset / kk)
							</SectionDivider>
							<TextField label="Kotitalous" source="orderedPrivate" />
							<TextField label="Yritys" source="orderedCompany" />
							<TextField label="Maatila" source="orderedFarm" />
							<TextField label="SOK" source="orderedSOK" />

							<SectionDivider />
							<Labeled label="Kuukauden tulokset">
								<SinglePersonCard />
							</Labeled>
						</Tab>
						<Tab
							label="Buukkaukset"
							icon={<BookIcon color="primary" />}
							path="bookings"
							title="Edustajan buukkaukset"
						>
							<ReferenceManyField
								reference="bookings"
								target="agentId"
								addLabel={false}
								sort={{ field: 'createdOn', order: 'DESC' }}
							>
								<BookingList
									zoneFilter={
										controllerProps.record && {
											agentId: controllerProps.record.id,
										}
									}
								/>
							</ReferenceManyField>
						</Tab>
					</TabbedShowLayout>
				</ShowView>
			)}
		</ShowController>
	)
}

export default AgentShow
