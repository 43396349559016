import React from 'react'
import { SaveButton, useRefresh, FormDataConsumer } from 'react-admin'
import { Drawer } from '@material-ui/core'
import {
	SimpleForm,
	Edit,
	Toolbar,
} from '../../components/overrides'
import TitleBar from '../bookingComponents/TitleBar'
import { makeStyles } from '@material-ui/core/styles'
import StartEndTimeInput from '../bookingComponents/StartEndTimeInput'
import { getLocation } from '../../effects'
import BusinessHoursField from '../../locations/BusinessHoursField'

const useStyles = makeStyles({
	drawer: {
		width: '25%',
	},
})

const ToolBar = (props) => {
	return (
		<Toolbar {...props}>
			<SaveButton />
		</Toolbar>
	)
}

const MeetingDateTimeEdit = (props) => {
	const classes = useStyles()
	const {
		setMeetingDateTimeActive,
		meetingDateTimeFormActive,
		...raProps
	} = props
	const refresh = useRefresh()

	const handleSuccess = () => {
		setMeetingDateTimeActive(false)
		refresh()
	}

	const toggleDrawer = (open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		setMeetingDateTimeActive(open)
	}

	return (
		<Drawer
			classes={{
				paper: classes.drawer,
			}}
			className={classes.drawer}
			anchor="right"
			open={meetingDateTimeFormActive}
			onClose={toggleDrawer(false)}
		>
			<Edit
				{...raProps}
				actions={null}
				onSuccess={handleSuccess}
				title=" "
				undoable={false}
			>
				<SimpleForm toolbar={<ToolBar />}>
				<FormDataConsumer>
					{({ formData, ...rest }) => (
						<>
							<TitleBar
								titleText="Valitse uusi tapaamisaika"
								setActive={setMeetingDateTimeActive}
							/>
							<StartEndTimeInput label="" source="appointmentStartsAt" />
							{formData.locationId ? (
								<BusinessHoursField record={getLocation(formData.locationId)} />
							) : null}
						</>
					)}
				</FormDataConsumer>
					
				</SimpleForm>
			</Edit>
		</Drawer>
	)
}

export default MeetingDateTimeEdit
