import React from 'react'
import { SimpleForm } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import CustomTitle from '../CustomTitle'

const useStyles = makeStyles({
	form: {
		width: '100%',
	},
})

export default ({ children, title, ...props }) => {
	const classes = useStyles()

	return (
		<SimpleForm {...props} redirect="show" className={classes.form}>
			<CustomTitle text={title} />
			{children}
		</SimpleForm>
	)
}
