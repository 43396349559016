import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
	palette: {
		primary: {
			/*
      main: "#00A1D4",
      light: "#5fd2ff",
      dark: "#0072a2",
      */
			main: '#00aced',
			light: '#65deff',
			dark: '#007dba',
		},
		secondary: {
			main: '#003665',
			light: '#3e5f93',
			dark: '#00103a',
		},
	},
	props: {
		MuiTableCell: {
			size: 'medium',
		},
		MuiInputLabel: {
			classes: {
				root: {
					'MuiInputLabel-root': {
						fontSize: '2rem',
					},
				},
			},
		},
		MuiFormLabel: {
			classes: {
				root: {
					'MuiFormLabel-root': {
						fontSize: '2rem',
					},
				},
			},
		},
	},
})
