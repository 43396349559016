import React from 'react'
import { FunctionField } from '../../components/overrides'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	label: {
		color: 'rgba(0, 0, 0, 0.54)',
		lineHeight: 1,
	},
	body: {
		fontSize: '.875rem',
		padding: '8px 0px 4px 0px',
	},
	wrapper: {
		margin: '8px 0px 4px 0px',
	},
})

const ListField = (props) => {
	const classes = useStyles()
	return (
		<FunctionField
			{...props}
			render={() => {
				return (
					<div className={classes.wrapper}>
						<Typography className={classes.label}>{props.label}</Typography>
						{props.record && props.record[props.source] ? (
							<Typography className={classes.body}>
								{props.record[props.source].join(', ')}
							</Typography>
						) : (
							<Typography>Ei tietoja</Typography>
						)}
					</div>
				)
			}}
		/>
	)
}

export default ListField
