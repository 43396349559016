import axios from 'axios'
import decode from 'jwt-decode'

export default {
	// called when the user attempts to log in
	login: async ({ sessionId, code }) => {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/users/verify`,
			{
				sessionId,
				code,
			},
		)
		if (response.data.jwt) {
			localStorage.setItem('jwt', response.data.jwt)
			localStorage.setItem('user', JSON.stringify(response.data.user))
			return Promise.resolve()
		}

	},

	// called when the user clicks on the logout button
	logout: () => {
		localStorage.removeItem('jwt')
		return Promise.resolve()
	},

	// called when the API returns an error
	checkError: (error) => {
		if (error.status === 401) {
			localStorage.removeItem('jwt')
			return Promise.reject()
		}
		return Promise.resolve()
	},

	// called when the user navigates to a new location, to check for authentication
	checkAuth: () => {
		const token = localStorage.getItem('jwt')
		if (token && Date.now() <= decode(token).exp * 1000) {
			//socket.emit('activeUser', token)
			return Promise.resolve()
		}
		return Promise.reject()
	},

	// called when the user navigates to a new location, to check for permissions / roles
	getPermissions: () => {
		const roles = JSON.parse(localStorage.getItem('user'))?.roles || []
		return Promise.resolve(roles)
	},
}
