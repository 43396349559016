import { resultCategories } from '../consts'

export default (status, data) => {
	return resultCategories.reduce((acc, curr) => {
		return {
			...acc,
			[curr]: {
				localAmount: data[`${status}${curr}Local`],
				remoteAmount: data[`${status}${curr}Remote`],
			},
		}
	}, {})
}
