export const stuff = {
	PRIVATE: {
		ordered: ['orderedPrivate'],
		completedLocal: ['completedOwnLocal', 'completedCompetitorLocal'],
		completedRemote: ['completedOwnRemote', 'completedCompetitorRemote'],
		confirmedLocal: ['confirmedOwnLocal', 'confirmedCompetitorLocal'],
		confirmedRemote: ['confirmedOwnRemote', 'confirmedCompetitorRemote'],
		canceledLocal: ['canceledOwnLocal', 'canceledCompetitorLocal'],
		canceledRemote: ['canceledOwnRemote', 'canceledCompetitorRemote'],
	},
	ORGANIZATION: {
		ordered: ['orderedCompany'],
		completedLocal: ['completedOrganizationLocal'],
		completedRemote: ['completedOrganizationRemote'],
		confirmedLocal: ['confirmedOrganizationLocal'],
		confirmedRemote: ['confirmedOrganizationRemote'],
		canceledLocal: ['canceledOrganizationLocal'],
		canceledRemote: ['canceledOrganizationRemote'],
	},
	FARM: {
		ordered: ['orderedFarm'],
		completedLocal: ['completedFarmLocal'],
		completedRemote: ['completedFarmRemote'],
		confirmedLocal: ['confirmedFarmLocal'],
		confirmedRemote: ['confirmedFarmRemote'],
		canceledLocal: ['canceledFarmLocal'],
		canceledRemote: ['canceledFarmRemote'],
	},
	SOK: {
		ordered: ['orderedSOK'],
		completedLocal: ['completedSOKLocal'],
		completedRemote: ['completedSOKRemote'],
		confirmedLocal: ['confirmedSOKLocal'],
		confirmedRemote: ['confirmedSOKRemote'],
		canceledLocal: ['canceledSOKLocal'],
		canceledRemote: ['canceledSOKRemote'],
	},
}

export default (agent, customerType) => {
	const {
		ordered,
		completedLocal,
		completedRemote,
		confirmedLocal,
		confirmedRemote,
		canceledLocal,
		canceledRemote,
	} = stuff[customerType]

	const sumResults = (arr) => arr.reduce((acc, curr) => acc + agent[curr], 0)

	return {
		ordered: sumResults(ordered),
		completedLocal: sumResults(completedLocal),
		completedRemote: sumResults(completedRemote),
		confirmedLocal: sumResults(confirmedLocal),
		confirmedRemote: sumResults(confirmedRemote),
		canceledLocal: sumResults(canceledLocal),
		canceledRemote: sumResults(canceledRemote),
	}
}
