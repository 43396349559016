import LocationList from './LocationList'
import LocationCreate from './LocationCreate'
import LocationEdit from './LocationEdit'
import LocationShow from './LocationShow'

export default {
	list: LocationList,
	create: LocationCreate,
	show: LocationShow,
	edit: LocationEdit,
}
