import React from 'react'
import TooltipIcon from './TooltipIcon'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
	tooltipField: {
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
	},
	tooltipText: {
		marginRight: 5,
	},
})

const TooltipField = (props) => {
	const classes = useStyles()
	return (
		<Typography
			component="span"
			variant="body2"
			className={classes.tooltipField}
		>
			<TooltipIcon {...props} />
		</Typography>
	)
}

export default TooltipField
