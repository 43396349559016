import axios from 'axios'

const getRecords = (month, year) => {
	const path =
		month || year
			? `${process.env.REACT_APP_API_URL}/records?${
					month ? `month=${month}&` : ''
			  }year=${year}`
			: `${process.env.REACT_APP_API_URL}/records`

	const token = localStorage.getItem('jwt')
	return axios
		.get(path, {
			headers: { Authorization: `Bearer: ${token}` },
		})
		.then((response) => {
			return response.data
		})
		.catch((error) => {})
}

export default getRecords
