import * as React from 'react'
import { Edit } from '../components/overrides'
import reformatRecord from './reformatRecord'
import LocationForm from './LocationForm'

const LocationEdit = (props) => {
	return (
		<Edit {...props} title="Muokkaa tapaamispaikkaa" transform={reformatRecord}>
			<LocationForm title="Muokkaa tapaamispaikkaa" editView />
		</Edit>
	)
}

export default LocationEdit
