import * as React from 'react'
import { Create } from '../components/overrides'
import AgentForm from './AgentForm'

const redirect = (basePath, id, data) => `../zones/${data.zoneId}/show/agents`

const AgentCreate = (props) => {
	return (
		<Create {...props} title="Lisää edustaja">
			<AgentForm
				initialValues={{ zoneId: 1 }}
				redirect={props.location.state ? redirect : 'show'}
				title="Lisää edustaja"
			/>
		</Create>
	)
}

export default AgentCreate
