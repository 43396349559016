import React from 'react'
import { DateField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	dateField: {
		width: '100%',
	},
})

export default (props) => {
	const classes = useStyles()

	return <DateField {...props} className={classes.dateField} locales="fi" />
}
