import React from 'react'
import { CreateButton, TopToolbar } from 'react-admin'

const ListActions = (props) => {
	return (
		<TopToolbar>
			<CreateButton basePath={props.basePath} />
		</TopToolbar>
	)
}

export default ListActions
