import Axios from 'axios'

const SetCancelBookingDetails = (id, token, cancelDetails, setSuccess) => {
	Axios.patch(
		`${process.env.REACT_APP_API_URL}/bookings/${id}/cancel?token=${token}`,
		cancelDetails,
	)
		.then(({ data }) => {
			setSuccess(true)
		})
		.catch((error) => {})
}

export default SetCancelBookingDetails
