import React, { useState, useEffect } from 'react'
import ResultOverview from '../components/ResultOverview'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GetAllZonesDetails } from '../effects'
import Loading from '../components/Loading'
import { usePermissions } from 'react-admin'
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles({
	container: {
		margin: '0 auto',
	},
	titleContainer: {
		marginBottom: '2rem',
		marginTop: '4rem',
	},
	infoText: {
		paddingTop: 20,
	},
	resultsContainer: {
		width: '1080px',
		margin: '0 auto',
		marginBottom: 80,
	},
	progressContainer: {
		marginTop: 100,
	},
	progressFirst: {
		marginBottom: 100,
		height: 5,
	},
	progress: {
		marginTop: 130,
		width: '100%',
		height: 5,
	},
})

export default () => {
	const classes = useStyles()
	const { loaded, permissions } = usePermissions()
	const [results, setResults] = useState()
	
	useEffect(() => {
		GetAllZonesDetails(setResults)
	}, [])

	return (
		loaded ? 
			(permissions.includes('ADMIN') || permissions.includes('COORDINATOR')) ? (
			<div className={classes.container}>
				<div className={classes.titleContainer}>
					<Typography variant="h4">Työpöytä</Typography>
					<Typography variant="body2" className={classes.infoText}>
						Täältä näet kuluvan kuukauden yleiskatsauksen kaikista alueista
					</Typography>
				</div>
				<div className={classes.resultsContainer}>
					{results ? (
						<ResultOverview results={results} />
					) : (
						<div className={classes.progressContainer}>
							<Loading />
						</div>
					)}
				</div>
			</div>
			) : (
				<Redirect to="/bookings/create" />
			)
		: "Lataa ..."
	)
}
