import React from 'react'
import { Tab } from 'react-admin'
import CustomTitle from '../CustomTitle'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	root: {
		'& span': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
			'& svg': {
				marginRight: '0.6rem',
			},
		},
	},
})

export default ({ title, children, ...props }) => {
	const classes = useStyles()
	return (
		<Tab {...props} classes={classes}>
			<CustomTitle text={title} />
			{children}
		</Tab>
	)
}
