export const resultCategories = ['Own', 'Competitor', 'Farm', 'Organization', "SOK"]

export const bookingResultTypes = [
	{
		targetKey: 'Bookings',
		label: 'Kaikki',
	},
	{
		targetKey: 'OrganizationLocal',
		label: 'Yritysasiakkaat',
	},
	{
		targetKey: 'OrganizationRemote',
		label: 'Yritysasiakkaat etä',
	},
	{
		targetKey: 'OwnLocal',
		label: 'Omat asiakkaat',
	},
	{
		targetKey: 'OwnRemote',
		label: 'Omat etä',
	},
	{
		targetKey: 'CompetitorLocal',
		label: 'Kilpailijan asiakkaat',
	},
	{
		targetKey: 'CompetitorRemote',
		label: 'Kilpailijan etä',
	},
	{
		targetKey: 'FarmLocal',
		label: 'Maatila asiakkaat',
	},
	{
		targetKey: 'FarmRemote',
		label: 'Maatila etä',
	},
	{
		targetKey: 'SOKLocal',
		label: 'SOK asiakkaat',
	},
	{
		targetKey: 'SOKRemote',
		label: 'SOK etä',
	},
]
