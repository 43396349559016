import React from "react";
import { SelectInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  selectInput: {
    width: "100%",
  },
});

export default (props) => {
  const classes = useStyles();

  const choices = props.sortAlphabetically ? 
    props.choices?.sort(function(a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    }) || []
    : props.choices

  return <SelectInput {...props} choices={choices} className={classes.selectInput} variant="outlined" />;
};
