import React from 'react'
import { Edit } from '../components/overrides'
import ZoneForm from './ZoneForm'

export default (props) => {
	return (
		<Edit {...props} title="Muokkaa aluetta">
			<ZoneForm title="Muokkaa aluetta" />
		</Edit>
	)
}
