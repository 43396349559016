import React from "react";
import { DateInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    dateInput: {
      width: "100%",
    },
  });
  
  export default (props) => {
    const classes = useStyles();
  
    return <DateInput {...props} className={classes.dateInput} variant="outlined" />;
  };