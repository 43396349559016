import React from 'react'
import { NumberField, LinearProgress } from 'react-admin'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Loading from './Loading'
import { bookingResultTypes } from '../consts'

//TODO: Bloated

const useStyles = makeStyles({
	actionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	container: {
		marginBottom: '2em',
		flex: 1,
	},
	item: {
		'@media print': {
			'page-break-inside': 'avoid',
		},
		margin: '0.5rem 0',
		borderLeft: '1px solid lightgray',
		borderTop: '1px solid lightgray',
		borderBottom: '1px solid lightgray',
	},
	grid: {},
	gridElement: {
		maxHeight: 20,
	},
	gridTitleRow: {
		paddingLeft: 10,
		fontWeight: 'bold',
		backgroundColor: 'rgba(245,245,245, 1)',
	},
	itemPadding: {
		paddingLeft: 10,
	},
	gridElementTopBorder: {
		maxHeight: 20,
		borderTop: '1px solid rgba(0, 0, 0, .2)',
	},
	gridElementFullBorders: {
		maxHeight: 20,
		borderTop: '1px solid rgba(0, 0, 0, .2)',
		borderRight: '1px solid rgba(0, 0, 0, .2)',
	},
	printButton: {
		maxWidth: 200,
		justifyContent: 'left',
		marginBottom: 30,
	},
	datePicker: {
		maxWidth: 200,
		marginBottom: 20,
	},
	reportDetailContainer: {
		marginBottom: '1em',
		display: 'flex',
		justifyContent: 'space-between',
	},
	monthYearContainer: {
		display: 'flex',
	},
	month: {
		paddingRight: '0.5em',
	},
	reportTitle: {
		paddingBottom: 50,
	},
	details: {
		fontWeight: 'bold',
	},
	gridHeaders: {
		fontWeight: 600,
	},
	header: {
		display: 'none',
		marginBottom: 30,
		'@media print': {
			display: 'inline-block',
		},
	},
})

// for showing all agents
const AgentReport = (props) => {
	const classes = useStyles()

	if (!props.agents) {
		return <Loading />
	}
	return (
		<div className={classes.container}>
			<div ref={props.componentRef}>
				<Typography className={classes.header} variant="h6">
					Edustaja raportti
				</Typography>
				{props.record && (
					<ReportDetails
						selectedTime={props.selectedTime}
						zoneName={props.record.name}
						monthMode
					/>
				)}
				<TotalStats
					data={props.agents}
					classes={classes}
					title="Edustajien yhteenveto"
					hasFirstTitle
				/>

				<Typography style={{ paddingLeft: 10 }} variant="h6">
					{' '}
					Edustajat{' '}
				</Typography>
				{props.agents.map((agent) => (
					<PersonCard key={agent.id} data={agent} classes={classes} />
				))}
			</div>
		</div>
	)
}

// for sales & coordinators
const UserReport = (props) => {
	const classes = useStyles()
	const admins = props.users.filter((person) => person.roles.includes('ADMIN'))
	const sales = props.users.filter((person) => person.roles.includes('SALES'))

	const coordinators = props.users.filter((person) =>
		person.roles.includes('COORDINATOR'),
	)
	return (
		<div>
			{props.users !== undefined ? (
				<div className={classes.container}>
					<div ref={props.componentRef}>
						<Typography className={classes.header} variant="h6">
							Myynti/Koordinaattori raportti
						</Typography>
						<ReportDetails
							selectedTime={props.selectedTime}
							monthMode={props.monthMode}
						/>
						<Grid container className={classes.grid}>
							<TotalStats
								data={props.users}
								classes={classes}
								title="Kaikkien yhteenveto"
								useConfirmed
								hasFirstTitle
							/>
							<TotalStats
								data={sales}
								classes={classes}
								title="Myyjien yhteenveto"
								useConfirmed
								hasFirstTitle
							/>
							{sales.map((person) => (
								<PersonCard
									key={person.id}
									data={person}
									classes={classes}
									useConfirmed
								/>
							))}
							<TotalStats
								data={coordinators}
								classes={classes}
								title="Koordinaattoreiden yhteenveto"
								useConfirmed
							/>
							{coordinators.map((person) => (
								<PersonCard
									key={person.id}
									data={person}
									classes={classes}
									useConfirmed
								/>
							))}
							<TotalStats
								data={admins}
								classes={classes}
								title="Hallinnon yhteenveto"
								useConfirmed
								hasFirstTitle
							/>
							{admins.map((person) => (
								<PersonCard
									key={person.id}
									data={person}
									classes={classes}
									useConfirmed
								/>
							))}
						</Grid>
					</div>
				</div>
			) : (
				<LinearProgress />
			)}
		</div>
	)
}

// for showing single agent
const SinglePersonCard = (props) => {
	const classes = useStyles()
	return (
		<React.Fragment>
			{props.record !== undefined ? (
				<div className={classes.container}>
					<div ref={props.componentRef}>
						<Grid container className={classes.grid}>
							<PersonCard data={props.record} classes={classes} isFirstTitle />
						</Grid>
					</div>
				</div>
			) : (
				<LinearProgress />
			)}
		</React.Fragment>
	)
}

const ReportDetails = ({ selectedTime, zoneName, monthMode }) => {
	const classes = useStyles()
	return (
		<div className={classes.reportDetailContainer}>
			<Typography className={classes.details} variant="body1">
				{zoneName}
			</Typography>
			<div className={classes.monthYearContainer}>
				{monthMode && (
					<Typography
						className={`${classes.month} ${classes.details}`}
						variant="body1"
					>
						{selectedTime.toLocaleString('fi', { month: 'long' })}
					</Typography>
				)}
				<Typography className={classes.details} variant="body1">
					{selectedTime.getFullYear()}
				</Typography>
			</div>
		</div>
	)
}

const resultStatusesAgents = [
	'confirmed',
	'completed',
	'invoiceReserved',
	'canceled',
]
const resultStatusesSales = ['confirmed', 'completed', 'canceled']

const TotalStats = ({
	data,
	classes,
	title,
	endTitle,
	useConfirmed,
	hasFirstTitle,
}) => {
	return (
		<Grid item container className={classes.item}>
			<GridRowTitle
				title={title}
				classes={classes}
				isFirstTitle={hasFirstTitle}
			/>
			<GridRowMainLabels classes={classes} useConfirmed={useConfirmed} />

			{bookingResultTypes.map((resultType) => (
				<FieldsTotalRow
					key={resultType.targetKey}
					data={data}
					useConfirmed={useConfirmed}
					leftLabel={resultType.label}
					classes={classes}
					sources={(useConfirmed
						? resultStatusesSales
						: resultStatusesAgents
					).map((status) => `${status}${resultType.targetKey}`)}
				/>
			))}
		</Grid>
	)
}
const PersonCard = ({ data, classes, useConfirmed, isFirstTitle }) => (
	<Grid item container className={classes.item}>
		<GridRowTitle
			isFirstTitle={isFirstTitle}
			title={data.lastName + ' ' + data.firstName}
			classes={classes}
			useConfirmed={useConfirmed}
		/>
		<GridRowMainLabels classes={classes} useConfirmed={useConfirmed} />

		{bookingResultTypes.map((resultType) => (
			<FieldsRow
				useConfirmed={useConfirmed}
				key={resultType.targetKey}
				data={data}
				leftLabel={resultType.label}
				classes={classes}
				sources={(useConfirmed
					? resultStatusesSales
					: resultStatusesAgents
				).map((status) => `${status}${resultType.targetKey}`)}
			/>
		))}
	</Grid>
)

const GridRowTitle = ({ classes, title, isFirstTitle }) => (
	<Grid item xs={12} style={{ borderRight: '1px solid lightgray' }}>
		<Typography
			className={
				isFirstTitle
					? classes.gridElement + ' ' + classes.gridTitleRow
					: classes.gridTitleRow
			}
			variant="body2"
		>
			{title}
		</Typography>
	</Grid>
)

// how large part is one grid item. 12 is full width, 6 half, 3 quarter...
// if 3 grid items are 2, they add up to 6, half of containers full width
const GridXs = 2

const GridRowMainLabels = ({ classes, useConfirmed }) => {
	const labels = useConfirmed
		? ['Tapaamiset', 'Vahvistetut', 'Toteutuneet', 'Peruutetut']
		: ['Tapaamiset', 'Tulevat', 'Toteutuneet', 'Vahvistetut', 'Peruutetut']

	return (
		<>
			{labels.map((label, index) => (
				<LabelCell
					useConfirmed={useConfirmed}
					label={label}
					small={index !== 0}
				/>
			))}
		</>
	)
}

const FieldsTotalRow = ({
	data,
	leftLabel,
	classes,
	sources,
	useConfirmed,
}) => {
	return (
		<>
			<LabelCell label={leftLabel} useConfirmed={useConfirmed} />
			{sources.map((source, index) => (
				<GridTotalField
					key={source}
					data={data}
					classes={classes}
					useRightBorder
					sourceName={sources[index]}
				/>
			))}
		</>
	)
}

const LabelCell = ({ label, useConfirmed, small }) => {
	const classes = useStyles()

	return (
		<Grid
			className={classes.gridElementFullBorders + ' ' + classes.itemPadding}
			item
			xs={small ? GridXs : useConfirmed ? GridXs * 3 : GridXs * 2}
		>
			<Typography className={classes.gridHeaders} variant="body2">
				{label}
			</Typography>
		</Grid>
	)
}

const FieldsRow = ({ data, classes, leftLabel, sources, useConfirmed }) => (
	<Grid container item>
		<LabelCell label={leftLabel} useConfirmed={useConfirmed} />
		{sources.map((source, index) => (
			<GridField
				key={source}
				data={data}
				classes={classes}
				useRightBorder
				source={sources[index]}
			/>
		))}
	</Grid>
)

const GridTotalField = ({ data, classes, useRightBorder, sourceName }) => {
	let total = 0
	data.map((person) => (total = total + person[sourceName]))
	return (
		<Grid
			className={
				useRightBorder
					? classes.gridElementFullBorders + ' ' + classes.itemPadding
					: classes.gridElementTopBorder + ' ' + classes.itemPadding
			}
			item
			xs={GridXs}
		>
			<Typography variant="body2">{total}</Typography>
		</Grid>
	)
}

const GridField = ({ data, source, classes, useRightBorder }) => (
	<Grid
		className={
			useRightBorder
				? classes.gridElementFullBorders + ' ' + classes.itemPadding
				: classes.gridElementTopBorder + ' ' + classes.itemPadding
		}
		item
		xs={GridXs}
	>
		<NumberField record={data} source={source} />
	</Grid>
)

export { AgentReport, SinglePersonCard, UserReport }
