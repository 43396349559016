import React from 'react'
import { BooleanField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	booleanField: {},
})

export default (props) => {
	const classes = useStyles()

	return <BooleanField {...props} className={classes.booleanField} />
}
