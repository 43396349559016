import React from 'react'
import { Labeled } from 'react-admin'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../theme'
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	PieChart,
	Pie,
	Cell,
	ResponsiveContainer,
} from 'recharts'
import ResultField from './ResultField'
import getBookingStatsByStatus from '../utils/getBookingStatsByStatus'

//TODO: Bloated

const ResultOverview = ({ results }) => {
	const agentsResult = Array.isArray(results) && CountAgentsStats(results)
	const classes = useStyles()
	return (
		<div style={{ width: '100%' }}>
			<div className={classes.container}>
				<ResultPanel
					type="completed"
					results={agentsResult ? agentsResult : results}
				/>
				<ResultPanel
					type="confirmed"
					results={agentsResult ? agentsResult : results}
				/>
				<ResultPanel
					type="canceled"
					results={agentsResult ? agentsResult : results}
				/>
			</div>
			<hr />
			<Typography variant="h6" className={classes.meetingPercentages}>
				Tapaamisten %-osuudet
			</Typography>
			<ResultPie results={agentsResult ? agentsResult : results} />
			<hr />
			<Typography className={classes.meetingCompletion}>
				Toteutumis %
			</Typography>
			<ResultGraphs results={agentsResult ? agentsResult : results} />
		</div>
	)
}

const TotalCount = (agents, type, results) => {
	const sources = {
		completed: 'completedBookings',
		confirmed: 'confirmedBookings',
		canceled: 'canceledBookings',
	}
	let total = 0
	results
		? (total = results[sources[type]])
		: agents &&
		agents.map((agent) => {
			total = total + agent[sources[type]]
			return null
		})
	return total
}

const ResultPanel = (props) => {
	const classes = useStyles()
	const label = {
		completed: 'Toteutuneet',
		confirmed: 'Vahvistetut (tulevat)',
		canceled: 'Peruutetut',
	}

	const stats = getBookingStatsByStatus(props.type, props.results)

	return (
		<Labeled label={label[props.type]}>
			<div>
				<div className={classes.resultFieldContainer}>
					<ResultField label="Oma" {...stats.Own} />
					<ResultField label="Kilpailija" {...stats.Competitor} />
					<ResultField label="Yritys" {...stats.Organization} />
					<ResultField label="Maatila" {...stats.Farm} />
					<ResultField label="SOK" {...stats.SOK} />
				</div>
				<div className={classes.totalCountContainer}>
					<Typography className={classes.totalCount} variant="body2">
						Yht
					</Typography>
					<Typography className={classes.totalCountNumber} variant="body2">
						{TotalCount(props.agents, props.type, props.results)}
					</Typography>
				</div>
			</div>
		</Labeled>
	)
}

const ResultPie = ({ results }) => {
	const classes = useStyles()
	const {
		completedBookings,
		completedOwnLocal,
		completedOwnRemote,
		completedCompetitorLocal,
		completedCompetitorRemote,
		completedOrganizationLocal,
		completedOrganizationRemote,
		completedFarmLocal,
		completedFarmRemote,
		completedSOKLocal,
		completedSOKRemote,
	} = results
	const completedPrivateLocal =
		((completedOwnLocal + completedCompetitorLocal) / completedBookings) * 100
	const completedPrivateRemote =
		((completedOwnRemote + completedCompetitorRemote) / completedBookings) * 100
	const completedOrganizationLocalPercentage =
		(completedOrganizationLocal / completedBookings) * 100
	const completedOrganizationRemotePercentage =
		(completedOrganizationRemote / completedBookings) * 100
	const completedFarmLocalPercentage =
		(completedFarmLocal / completedBookings) * 100
	const completedFarmRemotePercentage =
		(completedFarmRemote / completedBookings) * 100
	const completedSOKLocalPercentage =
		(completedSOKLocal / completedBookings) * 100
	const completedSOKRemotePercentage =
		(completedSOKRemote / completedBookings) * 100
	const completedArray = [
		{
			name: 'Yksityinen tapaaminen',
			completed: completedPrivateLocal,
		},
		{
			name: 'Yritys tapaaminen',
			completed: completedOrganizationLocalPercentage,
		},
		{
			name: 'Maatila tapaaminen',
			completed: completedFarmLocalPercentage,
		},
		{
			name: 'SOK tapaaminen',
			completed: completedSOKLocalPercentage,
		},
		{
			name: 'Yksityinen etä',
			completed: completedPrivateRemote,
		},
		{
			name: 'Yritys etä',
			completed: completedOrganizationRemotePercentage,
		},
		{
			name: 'Maatila etä',
			completed: completedFarmRemotePercentage,
		},
		{
			name: 'SOK etä',
			completed: completedSOKRemotePercentage,
		},
	]

	const colors = [theme.palette.primary.main, theme.palette.secondary.main]

	return (
		<div className={classes.percentagesPie}>
			{completedBookings === 0 ? (
				<Typography variant="body1" className={classes.noCompleted}>
					Ei toteutuneita
				</Typography>
			) : (
				<ResponsiveContainer width="100%" height={300}>
					<PieChart width={200} height={200}>
						<Pie
							data={completedArray}
							dataKey="completed"
							nameKey="name"
							isAnimationActive={false}
							innerRadius={70}
							labelLine={false}
							outerRadius={90}
							fill="#00aced"
							paddingAngle={6}
							label={(props) => <PieLabel {...props} />}
						>
							{completedArray.map((piePiece, index) => (
								<Cell
									key={`cell-${index}`}
									fill={index < 3 ? colors[0] : colors[1]}
								/>
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
			)}
		</div>
	)
}

const PieLabel = (props) => {
	const {
		cornerRadius,
		tooltipPayload,
		midAngle,
		middleRadius,
		tooltipPosition,
		innerRadius,
		outerRadius,
		maxRadius,
		startAngle,
		endAngle,
		paddingAngle,
		...rest
	} = props
	return (
		<text {...rest}>
			{rest.name} {`${rest.value.toFixed(2)}%`}
		</text>
	)
}

const ResultGraphs = ({ results }) => {
	const {
		completedOwnLocal,
		completedOwnRemote,
		completedCompetitorLocal,
		completedCompetitorRemote,
		completedOrganizationLocal,
		completedOrganizationRemote,
		completedFarmLocal,
		completedFarmRemote,
		completedSOKLocal,
		completedSOKRemote,
		canceledOwnLocal,
		canceledOwnRemote,
		canceledCompetitorLocal,
		canceledCompetitorRemote,
		canceledOrganizationLocal,
		canceledOrganizationRemote,
		canceledFarmLocal,
		canceledFarmRemote,
		canceledSOKLocal,
		canceledSOKRemote,
	} = results
	const completedAllLocal =
		completedOwnLocal + completedCompetitorLocal + completedOrganizationLocal + completedSOKLocal
	const completedAllRemote =
		completedOwnRemote + completedCompetitorRemote + completedOrganizationRemote + completedSOKRemote
	const dividers = {
		allLocal:
			completedOwnLocal +
			completedCompetitorLocal +
			completedOrganizationLocal +
			canceledOwnLocal +
			canceledCompetitorLocal +
			canceledOrganizationLocal +
			canceledSOKLocal +
			completedSOKLocal,
		allRemote:
			completedOwnRemote +
			completedCompetitorRemote +
			completedOrganizationRemote +
			canceledOwnRemote +
			canceledCompetitorRemote +
			canceledOrganizationRemote +
			canceledSOKRemote +
			completedSOKRemote,
		ownLocal: completedOwnLocal + canceledOwnLocal,
		ownRemote: completedOwnRemote + canceledOwnRemote,
		competitorLocal: completedCompetitorLocal + canceledCompetitorLocal,
		competitorRemote: completedCompetitorRemote + canceledCompetitorRemote,
		organizationlocal: completedOrganizationLocal + canceledOrganizationLocal,
		organizationRemote:
			completedOrganizationRemote + canceledOrganizationRemote,
		farmLocal: completedFarmLocal + canceledFarmLocal,
		farmRemote: completedFarmRemote + canceledFarmRemote,
		sokLocal: completedSOKLocal + canceledSOKLocal,
		sokRemote: completedSOKRemote + canceledSOKRemote,
	}

	const GetValue = (completed, divider) => {
		return divider > 0 ? (completed / divider) * 100 : 0
	}

	const zoneResultArray = [
		{
			label: 'Kaikki',
			totalLocal: GetValue(completedAllLocal, dividers['allLocal']),
			totalRemote: GetValue(completedAllRemote, dividers['allRemote']),
		},
		{
			label: 'Omat',
			totalLocal: GetValue(completedOwnLocal, dividers['ownLocal']),
			totalRemote: GetValue(completedOwnRemote, dividers['ownRemote']),
		},
		{
			label: 'Kilpailija',
			totalLocal: GetValue(
				completedCompetitorLocal,
				dividers['competitorLocal'],
			),
			totalRemote: GetValue(
				completedCompetitorRemote,
				dividers['competitorRemote'],
			),
		},
		{
			label: 'Yritys',
			totalLocal: GetValue(
				completedOrganizationLocal,
				dividers['organizationlocal'],
			),
			totalRemote: GetValue(
				completedOrganizationRemote,
				dividers['organizationRemote'],
			),
		},
		{
			label: 'Maatila',
			totalLocal: GetValue(completedFarmLocal, dividers['farmLocal']),
			totalRemote: GetValue(completedFarmRemote, dividers['farmRemote']),
		},
		{
			label: 'SOK',
			totalLocal: GetValue(completedSOKLocal, dividers['sokLocal']),
			totalRemote: GetValue(completedSOKRemote, dividers['sokRemote']),
		},
	]

	return (
		<ResponsiveContainer width="100%" height={300}>
			<BarChart width={600} height={300} data={zoneResultArray}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="label" />
				<YAxis width={30} domain={[0, 100]} />
				<Tooltip
					formatter={(value, name) => {
						return [
							value.toFixed(2) + '%',
							name === 'totalLocal' ? 'tapaaminen' : 'etä',
						]
					}}
				/>
				<Bar dataKey="totalLocal" fill={theme.palette.primary.main} />
				<Bar dataKey="totalRemote" fill={theme.palette.secondary.main} />
			</BarChart>
		</ResponsiveContainer>
	)
}

const CountAgentsStats = (agents) => {
	const totalStats =
		agents &&
		agents.reduce(
			(acc, curr) => {
				return {
					completedBookings: acc.completedBookings + curr.completedBookings,
					completedOwnLocal: acc.completedOwnLocal + curr.completedOwnLocal,
					completedOwnRemote: acc.completedOwnRemote + curr.completedOwnRemote,
					completedCompetitorLocal:
						acc.completedCompetitorLocal + curr.completedCompetitorLocal,
					completedCompetitorRemote:
						acc.completedCompetitorRemote + curr.completedCompetitorRemote,
					completedOrganizationLocal:
						acc.completedOrganizationLocal + curr.completedOrganizationLocal,
					completedOrganizationRemote:
						acc.completedOrganizationRemote + curr.completedOrganizationRemote,
					completedFarmLocal: acc.completedFarmLocal + curr.completedFarmLocal,
					completedFarmRemote:
						acc.completedFarmRemote + curr.completedFarmRemote,
					completedSOKLocal: acc.completedSOKLocal + curr.completedSOKLocal,
					completedSOKRemote: acc.completedSOKRemote + curr.completedSOKRemote,
					canceledBookings: acc.canceledBookings + curr.canceledBookings,
					canceledOwnLocal: acc.canceledOwnLocal + curr.canceledOwnLocal,
					canceledOwnRemote: acc.canceledOwnRemote + curr.canceledOwnRemote,
					canceledCompetitorLocal:
						acc.canceledCompetitorLocal + curr.canceledCompetitorLocal,
					canceledCompetitorRemote:
						acc.canceledCompetitorRemote + curr.canceledCompetitorRemote,
					canceledOrganizationLocal:
						acc.canceledOrganizationLocal + curr.canceledOrganizationLocal,
					canceledOrganizationRemote:
						acc.canceledOrganizationRemote + curr.canceledOrganizationRemote,
					canceledFarmLocal: acc.canceledFarmLocal + curr.canceledFarmLocal,
					canceledFarmRemote: acc.canceledFarmRemote + curr.canceledFarmRemote,
					canceledSOKLocal: acc.canceledSOKLocal + curr.canceledSOKLocal,
					canceledSOKRemote: acc.canceledSOKRemote + curr.canceledSOKRemote,
					confirmedBookings: acc.confirmedBookings + curr.confirmedBookings,
					confirmedOwnLocal: acc.confirmedOwnLocal + curr.confirmedOwnLocal,
					confirmedOwnRemote: acc.confirmedOwnRemote + curr.confirmedOwnRemote,
					confirmedCompetitorLocal:
						acc.confirmedCompetitorLocal + curr.confirmedCompetitorLocal,
					confirmedCompetitorRemote:
						acc.confirmedCompetitorRemote + curr.confirmedCompetitorRemote,
					confirmedOrganizationLocal:
						acc.confirmedOrganizationLocal + curr.confirmedOrganizationLocal,
					confirmedOrganizationRemote:
						acc.confirmedCompetitorRemote + curr.confirmedCompetitorRemote,
					confirmedFarmLocal: acc.confirmedFarmLocal + curr.confirmedFarmLocal,
					confirmedFarmRemote:
						acc.confirmedFarmRemote + curr.confirmedFarmRemote,
					confirmedSOKLocal: acc.confirmedSOKLocal + curr.confirmedSOKLocal,
					confirmedSOKRemote: acc.confirmedSOKRemote + curr.confirmedSOKRemote,
				}
			},
			{
				completedBookings: 0,
				completedOwnLocal: 0,
				completedOwnRemote: 0,
				completedCompetitorLocal: 0,
				completedCompetitorRemote: 0,
				completedOrganizationLocal: 0,
				completedOrganizationRemote: 0,
				completedFarmLocal: 0,
				completedFarmRemote: 0,
				completedSOKLocal: 0,
				completedSOKRemote: 0,
				canceledBookings: 0,
				canceledOwnLocal: 0,
				canceledOwnRemote: 0,
				canceledCompetitorLocal: 0,
				canceledCompetitorRemote: 0,
				canceledOrganizationLocal: 0,
				canceledOrganizationRemote: 0,
				canceledFarmLocal: 0,
				canceledFarmRemote: 0,
				canceledSOKLocal: 0,
				canceledSOKRemote: 0,
				confirmedBookings: 0,
				confirmedOwnLocal: 0,
				confirmedOwnRemote: 0,
				confirmedCompetitorLocal: 0,
				confirmedCompetitorRemote: 0,
				confirmedOrganizationLocal: 0,
				confirmedOrganizationRemote: 0,
				confirmedFarmLocal: 0,
				confirmedFarmRemote: 0,
				confirmedSOKLocal: 0,
				confirmedSOKRemote: 0,
			},
		)
	return totalStats
}

const useStyles = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: 400,
		width: '100%',
		marginTop: 10,
		justifyContent: 'space-between',
	},
	resultFieldContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		border: '1px solid rgba(0, 0, 0, 0.15)',
		paddingLeft: 5,
		paddingRight: 5,
	},
	totalCountContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	totalCount: {
		borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
		borderRight: '1px solid rgba(0, 0, 0, 0.15)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
		paddingTop: 2,
		paddingBottom: 2,
		paddingLeft: 5,
		paddingRight: 5,
	},
	totalCountNumber: {
		borderRight: '1px solid rgba(0, 0, 0, 0.15)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
		paddingTop: 2,
		paddingBottom: 2,
		paddingLeft: 5,
		paddingRight: 5,
		minWidth: 40,
	},
	resultField: {
		display: 'flex',
		alignItems: 'center',
	},
	resultLocal: {
		display: 'flex',
		alignItems: 'center',
	},
	resultRemote: {
		marginLeft: 10,
		display: 'flex',
		alignItems: 'center',
	},
	typoGraphy: {
		marginLeft: 5,
	},
	meetingPercentages: {
		paddingTop: 30,
		paddingBottom: 30,
	},
	percentagesPie: {
		paddingTop: 50,
		paddingBottom: 80,
	},
	meetingCompletion: {
		padding: 30,
		textAlign: 'center',
	},
	noCompleted: { textAlign: 'center', paddingBottom: 60, paddingTop: 60 },
	resultTitle: {
		paddingBottom: 50,
	},
})

export default ResultOverview
