import * as React from 'react'
import { Create } from '../components/overrides'
import reformatRecord from './reformatRecord'
import LocationForm from './LocationForm'

const LocationCreate = (props) => {
	return (
		<Create {...props} title="Lisää tapaamispaikka" transform={reformatRecord}>
			<LocationForm title="Lisää tapaamispaikka" />
		</Create>
	)
}

export default LocationCreate
