import AgentList from './AgentList'
import AgentShow from './AgentShow'
import AgentCreate from './AgentCreate'
import AgentEdit from './AgentEdit'

export default {
	list: AgentList,
	create: AgentCreate,
	show: AgentShow,
	edit: AgentEdit,
}
