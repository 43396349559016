import React from 'react'
import { Create } from '../components/overrides'
import ZoneForm from './ZoneForm'

export default (props) => {
	return (
		<Create {...props} title="Lisää alue">
			<ZoneForm title="Lisää alue" />
		</Create>
	)
}
