import React, { useEffect, useRef, useState } from 'react'
import { ReferenceField } from 'react-admin'
import { SimpleForm, Edit, TextField } from '../../components/overrides'
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
} from '@material-ui/core'
import CopyToClipboard from 'react-copy-to-clipboard'
import { makeStyles } from '@material-ui/core/styles'
import { SetBookingConfirmed } from '../../effects'
import AgentFullNameField from '../../components/AgentFullNameField'
import copyToClipboardText from '../../utils/copyToClipboardText'

const useStyles = makeStyles({
	dialogContentText: {
		wordWrap: 'break-word',
		'& pre': {
			lineHeight: 'normal',
		},
	},
})

const ConfirmButton = (props) => {
	const record = props.record
	const [cancel, { loading }] = SetBookingConfirmed(record)
	return (
		<Button
			color="primary"
			variant="contained"
			onClick={() => {
				cancel()
				props.setConfirmBookingActive(false)
			}}
			disabled={loading || !record.agentId}
		>
			Vahvista
		</Button>
	)
}

const ConfirmDialog = (props) => {
	const { record } = props
	const textRef = useRef(null)
	const classes = useStyles()
	const SELF_URL = process.env.REACT_APP_SELF_URL
	const copyText = copyToClipboardText(record, SELF_URL)

	const [text, setText] = useState('');

	return (
		<span>
			<DialogTitle>Buukkauksen vahvistus</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Vahvista buukkauksen tiedot oikeiksi ja merkitse edustajan
					kalenteriin. Voit kopioida buukkauksen tiedot allaolevasta napista.
				</DialogContentText>
				<DialogContentText>
					Valittu edustaja:{' '}
					<ReferenceField source="agentId" reference="agents" {...props}>
						<AgentFullNameField />
					</ReferenceField>
				</DialogContentText>
				<DialogContentText>
					Edustajan sähköposti:{' '}
					<ReferenceField
						source="agentId"
						reference="agents"
						{...props}
						link={null}
					>
						<TextField source="email" />
					</ReferenceField>
				</DialogContentText>
				<DialogContentText>Buukkauksen tiedot:</DialogContentText>
				<DialogContentText className={classes.dialogContentText}>
					<pre
						contentEditable
						suppressContentEditableWarning
						ref={textRef}
						onInput={(e) => setText(e.currentTarget.innerHTML)}
						onBlur={(e) => setText(e.currentTarget.innerHTML)}>{copyText}</pre>
				</DialogContentText>
				<DialogContentText>
					<CopyToClipboard text={text} options={{ format: 'text/plain' }}>
						<Button color="secondary" variant="outlined">
							Kopioi teksti
						</Button>
					</CopyToClipboard>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<ConfirmButton
					record={props.record}
					setConfirmBookingActive={props.setConfirmBookingActive}
				/>
				<Button
					color="secondary"
					onClick={() => props.setConfirmBookingActive(false)}
				>
					Älä vahvista
				</Button>
			</DialogActions>
		</span>
	)
}

const ConfirmBooking = (props) => {
	const { setConfirmBookingActive, confirmBookingActive, ...raProps } = props
	return (
		<Dialog
			maxWidth="lg"
			open={props.confirmBookingActive}
			onClose={() => setConfirmBookingActive(false)}
		>
			<Edit {...raProps} actions={null} title=" ">
				<SimpleForm toolbar={null}>
					<ConfirmDialog setConfirmBookingActive={setConfirmBookingActive} />
				</SimpleForm>
			</Edit>
		</Dialog>
	)
}

export default ConfirmBooking
