import axios from 'axios'

const postRetrial = (id, notify) => {
	const token = localStorage.getItem('jwt')
	return axios
		.post(`${process.env.REACT_APP_API_URL}/bookings/${id}/retrial`, null, {
			headers: { Authorization: `Bearer: ${token}` },
		})
		.then((response) => {
			return response.data.id
		})
		.catch((error) => {
			notify('Buukkauksen palauttaminen ei onnistunut', 'warning')
		})
}

export default postRetrial
