import React from 'react'
import { CheckboxGroupInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	checkboxGroupInput: {
		'& .RaCheckboxGroupInput-label-69': {
			transform: 'unset',
		},
	}
})

export default (props) => {
	const classes = useStyles()
	return (
		<CheckboxGroupInput
			{...props}
			className={classes.checkboxGroupInput}
			variant="outlined"
			options={{ row: false }}
		/>
	)
}
