import * as React from 'react'
import {
	Edit,
} from '../components/overrides'
import AgentForm from './AgentForm'

const redirect = (basePath, id, data) => `../zones/${data.zoneId}/show/agents`

const AgentEdit = (props) => {
	return (
		<Edit {...props} title="Muokkaa edustajaa">
			<AgentForm
				redirect={props.location.state ? redirect : 'show'}
				title="Muokkaa edustajaa"
			/>
		</Edit>
	)
}

export default AgentEdit
