import React from 'react'
import { Sidebar, Menu } from 'react-admin'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
	menuitem: {
		'& a': {
			margin: '0.8rem 0rem',
		},
	},
})

export default (props) => {
	const classes = useStyles()
	return (
		<Sidebar {...props}>
			<Menu
				hasDashboard
				classes={{
					main: classes.menuitem,
				}}
			/>
		</Sidebar>
	)
}
