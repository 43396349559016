import React from 'react'
import { SimpleShowLayout } from 'react-admin'
import { AgentReport } from '../components/PersonReports'
import ResultOverview from '../components/ResultOverview'
import Loading from '../components/Loading'
import { Typography } from '../components/overrides'

export default (props) => {
	if (!props.agents) {
		return <Loading />
	}

	return (
		<SimpleShowLayout {...props}>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					gridGap: '2rem',
					width: '100%',
				}}
			>
				<AgentReport {...props} />
				<div>
					<Typography variant="body1"> Alueen kokonaistulokset </Typography>
					<ResultOverview results={props.agents} />
				</div>
			</div>
		</SimpleShowLayout>
	)
}
