import React from 'react'
import { ArrayField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	arrayField: {},
})

export default (props) => {
	const classes = useStyles()

	return <ArrayField {...props} className={classes.arrayField} />
}
