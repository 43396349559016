import { useUpdate } from 'react-admin'

const SetBookingConfirmed = (record) => {
	const { id } = JSON.parse(localStorage.getItem('user'))
	const changedData = {
		status: 'CONFIRMED',
		confirmedBy: id,
	}
	const [cancel, { loading }] = useUpdate(
		'bookings',
		record.id,
		{ ...record, ...changedData },
		record,
	)

	return [cancel, loading]
}

export default SetBookingConfirmed
