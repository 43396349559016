import React from 'react'
import { TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	input: {},
})

export default (props) => {
	const classes = useStyles()

	return (
		<TextInput
			{...props}
			className={classes.input}
			variant="outlined"
			fullWidth
		/>
	)
}
