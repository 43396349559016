import React from 'react'
import { FunctionField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	style: {
		width: '100%',
	},
})

export default (props) => {
	const classes = useStyles()

	return <FunctionField {...props} className={classes.style} />
}
