import React from 'react'
import { FormDataConsumer } from 'react-admin'
import { useForm } from 'react-final-form'
import {
	TextInput,
	SimpleForm,
	DateInput,
	RadioButtonGroupInput,
	SelectInput,
	ReferenceInput,
	CheckboxGroupInput,
} from '../components/overrides'
import StartEndTimeInput from './bookingComponents/StartEndTimeInput'
import {
	validateRadioButton,
	validateName,
	validateBirth,
	validateAddress,
	validatePhoneNumber,
	validateInsuredCount,
	validateEmail,
} from '../utils/FormValidators'
import SectionDivider from '../components/SectionDivider'
import BusinessHoursField from '../locations/BusinessHoursField'
import { getLocation, GetZoneLocations } from '../effects'
import { validateZone } from '../utils/FormValidators'
import { mapTranslationsToArray } from '../utils/fieldTranslations'

const BookingForm = ({ title, ...props }) => {
	const completed =
		props.record && props.record.status === 'COMPLETED' ? true : false

	const isPrivateCustomer = (formData) =>
		formData.customer?.customerType === 'PRIVATE' ||
		(formData.customer?.customerType === "FARM" && formData.customer?.farmCustomerType === 'PRIVATE') ||
		formData.customer?.customerType === 'SOK';

	return (
		<SimpleForm
			initialValues={{ zoneId: 1, insurancesFromCompetitors: [] }}
			title={title}
			{...props}
		>
			<FormDataConsumer>
				{({ formData, ...rest }) => (
					<>
						<ZoneLocationInput {...formData} completed={completed} />
						<RadioButtonGroupInput
							label="Tapaamistyyppi"
							source="meetingType"
							validate={validateRadioButton}
							choices={mapTranslationsToArray('meetingType')}
						/>
						<StartEndTimeInput
							label="Tapaamisen ajankohta"
							source="appointmentStartsAt"
							disabled={completed}
						/>
						{formData.locationId ? (
							<BusinessHoursField record={getLocation(formData.locationId)} />
						) : null}

						<SectionDivider>Asiakas</SectionDivider>
						<RadioButtonGroupInput
							label="Kotitalous, yritys, maatila vai SOK"
							source="customer.customerType"
							validate={validateRadioButton}
							choices={mapTranslationsToArray('customerType')}
						/>
						{formData.customer?.customerType === 'FARM' ? (
							<RadioButtonGroupInput
								label="Maatila: kotitalous vai yritys"
								source="customer.farmCustomerType"
								validate={validateRadioButton}
								choices={mapTranslationsToArray('farmCustomerType')}
							/>
						) : null}
						<TextInput
							label="Asiakkaan nimi"
							source="customer.name"
							validate={validateName}
						/>
						<TextInput
							label="Asiakkaan osoite"
							source="customer.address"
							validate={validateAddress}
						/>
						<TextInput
							label="Asiakkaan puhelinnumero"
							source="customer.gsm"
							validate={validatePhoneNumber}
						/>
						<TextInput
							label="Asiakkaan sähköposti"
							source="customer.email"
							validate={validateEmail}
						/>
						{isPrivateCustomer(formData) ? (
							<DateInput
								label="Asiakkaan syntymäaika"
								source="customer.birthday"
								validate={validateBirth}
								{...rest}
							/>
						) : null}
						<SectionDivider>Laskutustiedot</SectionDivider>
						<RadioButtonGroupInput
							label="Asiakkaan luottotiedot"
							source="creditCheckAllowed"
							validate={validateRadioButton}
							choices={mapTranslationsToArray('creditCheckAllowed')}
						/>
						{isPrivateCustomer(formData) ? null : (
							<RadioButtonGroupInput
								label="Yrityksen koko"
								source="customer.organizationSize"
								validate={validateRadioButton}
								choices={mapTranslationsToArray('organizationSize')}
								{...rest}
							/>
						)}
						{isPrivateCustomer(formData) ? null : (
							<RadioButtonGroupInput
								label="Yrityksen liikevaihto"
								source="customer.organizationRevenue"
								validate={validateRadioButton}
								choices={mapTranslationsToArray('organizationRevenue')}
								{...rest}
							/>
						)}
						{isPrivateCustomer(formData) ? (
							<SelectInput
								label="Talouden vakuutettavat henkilöt"
								source="amountToBeInsured"
								validate={validateInsuredCount}
								{...rest}
								choices={[
									{ id: '1', name: '1' },
									{ id: '2', name: '2' },
									{ id: '3', name: '3' },
									{ id: '4', name: '4' },
									{ id: '5', name: '5' },
									{ id: '6', name: '6' },
									{ id: '7', name: '7' },
									{ id: '8', name: '8' },
									{ id: '9', name: '9' },
									{ id: '10', name: '10' },
								]}
							/>
						) : (
							<TextInput source="customer.businessId" label="Y-tunnus" />
						)}
						<CheckboxGroupInput
							label="Vakuutusyhtiöt, joista vakuutuksia"
							source="insuranceCompanies"
							choices={[
								{ id: 'Fennia', name: 'Fennia' },
								{ id: 'IF', name: 'IF' },
								{ id: 'LähiTapiola', name: 'LähiTapiola' },
								{ id: 'Pohjola', name: 'Pohjola' },
								{ id: 'Pohjantähti', name: 'Pohjantähti' },
								{ id: 'POP-vakuutus', name: 'POP-vakuutus' },
								{ id: 'Turva', name: 'Turva' },
								{ id: 'Ei tietoa', name: 'Ei tietoa' },
							]}
						/>
						{formData.insuranceCompanies?.find(
							(item) => item !== 'LähiTapiola' && item !== 'Turva',
						) ? (
							<CheckboxGroupInput
								label="Mitä erilaisia vakuutuksia löytyy nyt kilpailijoilta (muu kuin LähiTapiola tai Turva)"
								source="insurancesFromCompetitors"
								choices={[
									{ id: 'Kotivakuutus', name: 'Kotivakuutus' },
									{ id: 'Liikennevakuutus', name: 'Liikennevakuutus' },
									{ id: 'Kaskovakuutus', name: 'Kaskovakuutus' },
									{ id: 'Henkilövakuutuksia', name: 'Henkilövakuutuksia' },
									{ id: 'Henkivakuutus', name: 'Henkivakuutus' },
									{
										id: 'Yritysten vakuutuksia',
										name: 'Yritysten vakuutuksia',
									},
									{ id: 'Ei tietoa', name: 'Ei tietoa' },
								]}
							/>
						) : null}
						<TextInput label="Vapaa sana" multiline rows={3} source="notes" />
						<SectionDivider>Muut</SectionDivider>
						<RadioButtonGroupInput
							label="Asiakkaalle käyty läpi vakuutuskirjojen mukaan/esille ottaminen"
							source="informedClient"
							validate={validateRadioButton}
							choices={mapTranslationsToArray('informedClient')}
						/>
						<RadioButtonGroupInput
							label="Asiakkaalle käyty sitouttaminen läpi"
							source="informedBinding"
							validate={validateRadioButton}
							choices={mapTranslationsToArray('informedBinding')}
						/>
						<RadioButtonGroupInput
							label="Mitä soittolistaa?"
							source="customerSource"
							validate={validateRadioButton}
							choices={mapTranslationsToArray('customerSource')}
						/>
					</>
				)}
			</FormDataConsumer>
		</SimpleForm>
	)
}

const ZoneLocationInput = ({ zoneId, completed }) => {
	const form = useForm()

	return (
		<React.Fragment>
			<ReferenceInput
				label="Alue"
				source="zoneId"
				reference="zones"
				perPage={100}
				onChange={() => form.change('locations', null)}
				validate={validateZone}
				disabled={completed}
			>
				<SelectInput sortAlphabetically />
			</ReferenceInput>
			<SelectInput
				allowEmpty
				label="Tapaamispaikka"
				source="locationId"
				choices={GetZoneLocations(zoneId)}
				sortAlphabetically
			/>
		</React.Fragment>
	)
}

export default BookingForm
