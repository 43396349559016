import { useUpdate } from 'react-admin'

const SetBookingArchived = (record) => {
	const changedData = {
		archived: true,
	}
	const [archive, { loading }] = useUpdate(
		'bookings',
		record.id,
		{ ...record, ...changedData },
		record,
	)
	return [archive, loading]
}

export default SetBookingArchived
