import React from "react";
import { DateTimeInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    dateTimeInput: {
      width: "100%",
    },
  });
  
  export default (props) => {
    const classes = useStyles();
  
    return <DateTimeInput {...props} ampm={false} className={classes.dateTimeInput} variant="outlined" />;
  };