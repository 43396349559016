import React, { useState, useEffect, useRef } from 'react'
import { cloneElement } from 'react'
import {
	Datagrid,
	TextField,
	EmailField,
	ArrayField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	ChipField,
	TabbedShowLayout,
	Tab,
	Pagination,
} from 'react-admin'
import {
	Create,
	Edit,
	List,
	TextInput,
	SimpleForm,
	SelectArrayInput,
	PasswordInput,
	Typography,
	ListActions,
} from '../components/overrides'
import { UserReport } from '../components/PersonReports'
import {
	validateText,
	validateEmail,
	validateRole,
} from '../utils/FormValidators'
import UserReportPanel from './UserReportPanel'
import { useReactToPrint } from 'react-to-print'
import { makeStyles } from '@material-ui/core/styles'
import { getRecords } from '../effects'
import Loading from '../components/Loading'
import ResultOverview from '../components/ResultOverview'

const useStyles = makeStyles({
	userReportContainer: {
		display: 'flex',
	},
})

export const StringToLabelObject = ({ record, children, ...rest }) =>
	cloneElement(children, {
		record: { label: record },
		...rest,
	})

const list = (props) => {
	return (
		<List
			{...props}
			title="Käyttäjähallinta"
			bulkActionButtons={false}
			pagination={
				props.history.location.pathname === '/users/report' ? null : (
					<Pagination />
				)
			}
			actions={<ListActions />}
		>
			<CustomTabbedShowLayout />
		</List>
	)
}

const CustomTabbedShowLayout = (props) => {
	//	<BooleanField label="Aktiivinen" source="isActive" />


	return (
		<div>
			<TabbedShowLayout syncWithLocation={false}>
				<Tab label="Lista">
					<Datagrid rowClick="show">
						<TextField label="Käyttäjätunnus" source="username" />
						<TextField label="Etunimi" source="firstName" />
						<TextField label="Sukunimi" source="lastName" />
						<EmailField label="Sähköposti" source="email" />
						<ArrayField label="Roolit" source="roles">
							<SingleFieldList>
								<StringToLabelObject>
									<ChipField source="label" />
								</StringToLabelObject>
							</SingleFieldList>
						</ArrayField>
					</Datagrid>
				</Tab>
				<Tab label="Raportti">
					<UserTabContent data={props.data} />
				</Tab>
			</TabbedShowLayout>
		</div>
	)
}

const UserTabContent = (props) => {
	const [monthMode, setMonthMode] = useState(true)
	const [selectedTime, setSelectedTime] = useState(new Date())
	const [records, setRecords] = useState([])

	useEffect(() => {
		(async () => {
			const month = monthMode ? selectedTime.getMonth() + 1 : null
			const year = selectedTime.getFullYear()
			const records = await getRecords(monthMode ? month : null, year)
			setRecords(records)
		})()
	}, [selectedTime, monthMode])

	const componentRef = useRef()
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Myyja_Koordinaattoriraportti',
		pageStyle: '',
	})
	return (
		<React.Fragment>
			<UserReportPanel
				selectedTime={selectedTime}
				handlePrint={handlePrint}
				setSelectedTime={setSelectedTime}
				setMonthMode={setMonthMode}
				monthMode={monthMode}
				data={props.data}
			/>
			<UserReportContainer
				data={records}
				monthMode={monthMode}
				selectedTime={selectedTime}
				componentRef={componentRef}
			/>
		</React.Fragment>
	)
}

const UserReportContainer = (props) => {
	const classes = useStyles()
	const { data } = props
	if (!data) return <Loading />
	let dataArray = []
	Object.keys(data).map((id) => {
		const newData = { ...data[id], ...data[id].userResults }
		dataArray.push(newData)
		return null
	})
	return (
		<div className={classes.userReportContainer}>
			<div style={{ width: '100%', marginRight: '1rem' }}>
				<UserReport
					users={dataArray}
					selectedTime={props.selectedTime}
					componentRef={props.componentRef}
					monthMode={props.monthMode}
				/>
			</div>

			<div style={{ marginTop: '2rem', width: '100%' }}>
				<ResultOverview results={data} />
			</div>
		</div>
	)
}

const NameTitle = ({ record }) => {
	return (
		<div>
			<Typography variant="h5">
				{record.firstName} {record.lastName}
			</Typography>
			<hr />
		</div>
	)
}

const show = (props) => {
	return (
		<Show title="Käyttäjä" {...props}>
			<SimpleShowLayout>
				<NameTitle />
				<TextField label="Käyttäjätunnus" source="username" />
				<EmailField label="Sähköposti" source="email" />
				<ArrayField label="Roolit" source="roles">
					<SingleFieldList>
						<StringToLabelObject>
							<ChipField source="label" />
						</StringToLabelObject>
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	)
}

const create = (props) => {
	return (
		<Create {...props} title="Lisää käyttäjä">
			<UserForm title="Lisää käyttäjä" />
		</Create>
	)
}

const edit = (props) => {
	return (
		<Edit title="Muokkaa käyttäjää" {...props}>
			<UserForm title="Muokkaa käyttäjää" />
		</Edit>
	)
}

export default { list, create, edit, show }

/***********************************************/

const UserForm = ({ title, ...props }) => (
	<SimpleForm title={title} autoComplete="off" {...props}>
		<TextInput
			label="Käyttäjätunnus"
			source="username"
			validate={validateText}
			autoComplete="off"
		/>
		<TextInput label="Etunimi" source="firstName" validate={validateText} />
		<TextInput label="Sukunimi" source="lastName" validate={validateText} />

		<TextInput
			label="Sähköposti"
			source="email"
			validate={validateEmail}
			autoComplete="off"
		/>
		<TextInput label="Puhelinnumero" source="phoneNumber" />
		<PasswordInput
			label="Salasana"
			source="password"
			autoComplete="new-password"
		/>
		<SelectArrayInput
			validate={validateRole}
			label="Rooli"
			source="roles"
			optionText="name"
			choices={[
				{ id: 'ADMIN', name: 'ADMIN' },
				{ id: 'COORDINATOR', name: 'COORDINATOR' },
				{ id: 'SALES', name: 'SALES' },
			]}
		/>
	</SimpleForm>
)
