import React from 'react'
import { FormDataConsumer, SaveButton, useRefresh } from 'react-admin'
import {
	SimpleForm,
	Edit,
	TextInput,
	RadioButtonGroupInput,
	Toolbar,
} from '../../components/overrides'
import { Drawer } from '@material-ui/core'
import TitleBar from '../bookingComponents/TitleBar'
import { validateRadioButton } from '../../utils/FormValidators'
import { makeStyles } from '@material-ui/core/styles'
import { mapTranslationsToArray } from '../../utils/fieldTranslations'

const CancelBooking = (props) => {
	const classes = useStyles()
	const refresh = useRefresh()
	const { setCancelBookingActive, cancelBookingActive, ...raProps } = props

	const handleSuccess = (data) => {
		setCancelBookingActive(false)
		refresh()
	}

	return (
		<Drawer
			anchor="right"
			classes={{
				paper: classes.drawer,
			}}
			open={cancelBookingActive}
			onClose={() => setCancelBookingActive(false)}
		>
			<Edit
				{...raProps}
				actions={null}
				onSuccess={handleSuccess}
				title=" "
				undoable={false}
			>
				<SimpleForm toolbar={<ActionToolbar />}>
					<TitleBar
						titleText="Buukkauksen peruutus"
						setActive={setCancelBookingActive}
					/>

					<RadioButtonGroupInput
						label="Siirrettävä vai peruttava tapaaminen"
						source="cancelDetails.cancelType"
						validate={validateRadioButton}
						choices={mapTranslationsToArray('cancelType')}
					/>
					<RadioButtonGroupInput
						label="Siirron / peruuntumisen syy"
						source="cancelDetails.cancelReason"
						validate={validateRadioButton}
						choices={mapTranslationsToArray('cancelReason')}
					/>
					<FormDataConsumer>
						{({ formData }) => {
							return (
								<>
									{formData.cancelDetails &&
									formData.cancelDetails.cancelReason === 'OTHER' ? (
										<TextInput
											label="Mikä syy?"
											multiline
											source="cancelDetails.reasonDetails"
										/>
									) : (
										''
									)}
								</>
							)
						}}
					</FormDataConsumer>

					<TextInput
						label="Vapaa sana"
						multiline
						source="cancelDetails.otherDetails"
					/>
				</SimpleForm>
			</Edit>
		</Drawer>
	)
}

const useStyles = makeStyles({
	drawer: {
		width: '50%',
	},
})

const ActionToolbar = (props) => {
	return (
		<Toolbar {...props}>
			<SaveButton transform={(data) => ({ ...data, status: 'CANCELED' })} />
		</Toolbar>
	)
}

export default CancelBooking
