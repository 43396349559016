import React from 'react'
import { FunctionField } from '../../components/overrides'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	label: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '1rem',
		lineHeight: 1,
	},
	body: {
		padding: '8px 0px 4px 0px',
	},
	wrapper: {
		margin: '8px 0px 4px 0px',
	},
})

const ConfirmedByFullNameField = (props) => {
	const classes = useStyles()

	return (
		<FunctionField
			{...props}
			render={() => {
				//hack to show the label properly
				return props.showCustomLabel ? (
					<div className={classes.wrapper}>
						<Typography className={classes.label}>{props.label}</Typography>
						{props.record && props.record.confirmedBy?.firstName && props.record.confirmedBy?.lastName ? (
							<Typography className={classes.body}>
								{props.record.confirmedBy?.firstName + ' ' + props.record.confirmedBy?.lastName}
							</Typography>
						) : (
							<Typography>Ei tietoja</Typography>
						)}
					</div>
				) : props.record && props.record.confirmedBy?.firstName && props.record.confirmedBy?.lastName ? (
					`${props.record.confirmedBy?.firstName} ${props.record.confirmedBy?.lastName}`
				) : (
					'Ei tietoja'
				)
			}}
		/>
	)
}

export default ConfirmedByFullNameField
