import ZoneShow from './ZoneShow'
import ZoneEdit from './ZoneEdit'
import ZoneCreate from './ZoneCreate'
import ZoneList from './ZoneList'

export default {
	list: ZoneList,
	create: ZoneCreate,
	show: ZoneShow,
	edit: ZoneEdit,
}
