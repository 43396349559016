import * as React from 'react'
import { fetchUtils, Admin, Resource } from 'react-admin'
import { Route, Redirect } from 'react-router-dom'
import crudProvider from './utils/CrudProvider'
import authProvider from './authProvider'
import users from './users'
import zones from './zones'
import theme from './theme'
import CustomLayout from './components/Layout'
import me from './users/me'
import bookings from './bookings'
import agents from './agents'
import Dashboard from './dashboard'
import locations from './locations'
import GroupIcon from '@material-ui/icons/Group'
import LocationIcon from '@material-ui/icons/LocationCity'
import PlaceIcon from '@material-ui/icons/Place'
import PersonIcon from '@material-ui/icons/PersonAdd'
import BookIcon from '@material-ui/icons/Book'
import finnishMessages from 'ra-language-finnish'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import CancelBookingForm from './bookings/CancelBookingForm'
import { Login } from './login'
import SystemInfo from './systemInfo/SystemInfo'

const i18nProvider = polyglotI18nProvider(() => finnishMessages, 'fi')

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' })
	}
	const token = localStorage.getItem('jwt')
	options.headers.set('Authorization', `Bearer: ${token}`)
	return fetchUtils.fetchJson(url, options)
}

const dataProvider = crudProvider(process.env.REACT_APP_API_URL, httpClient)

const App = () => (
	<Admin
		theme={theme}
		layout={CustomLayout}
		dashboard={() => <Dashboard />}
		dataProvider={dataProvider}
		authProvider={authProvider}
		loginPage={Login}
		customRoutes={[
			<Route key="me" path="/me" component={me.edit} />,
			<Route
				key="cancel-booking"
				path="/cancel-booking/:id/:token"
				component={CancelBookingForm}
			/>,
			<Route key="system-info" path="/system-info" component={SystemInfo} />,
		]}
		i18nProvider={i18nProvider}
	>
		{(permissions) => {
			const isAdmin = permissions.includes('ADMIN')
			const isCoordinator = permissions.includes('COORDINATOR')
			const userActions = isAdmin ? users : null
			const zoneActions = isAdmin || isCoordinator ? zones : null
			const agentActions = isAdmin || isCoordinator ? agents : null
			const locationActions = isAdmin || isCoordinator ? locations : null

			return [
				<Resource
					name="users"
					{...userActions}
					options={{
						label: 'Käyttäjähallinta',
					}}
					icon={PersonIcon}
				/>,
				<Resource
					name="bookings"
					create={bookings.create}
					list={isAdmin || isCoordinator ? bookings.list : () => <Redirect to="/bookings/create" />}
					show={isAdmin || isCoordinator ? bookings.show : () => <Redirect to="/bookings/create" />}
					edit={isAdmin || isCoordinator ? bookings.edit : () => <Redirect to="/bookings/create" />}
					icon={BookIcon}
					options={{
						label: 'Buukkaukset',
					}}
				/>,
				<Resource
					name="zones"
					{...zoneActions}
					icon={PlaceIcon}
					options={{
						label: 'Alueet',
					}}
				/>,
				<Resource name="me" />,
				<Resource
					name="agents"
					{...agentActions}
					icon={GroupIcon}
					options={{
						label: 'Edustajat',
					}}
				/>,
				<Resource
					name="locations"
					{...locationActions}
					icon={LocationIcon}
					options={{
						label: 'Tapaamispaikat',
					}}
				/>,
			]
		}}
	</Admin>
)

export default App
