import React, { useEffect, useState } from 'react'
import { FormDataConsumer, Labeled } from 'react-admin'
import { CardHeader } from '@material-ui/core'
import {
	Card,
	CardContent,
	Typography,
	CardActions,
	Drawer,
	Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SimpleForm, Edit } from '../../components/overrides'
import TitleBar from '../bookingComponents/TitleBar'
import {
	GetZoneAgents,
	SetBookingAgent,
	RemoveBookingAgent,
} from '../../effects'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ResultField from '../../components/ResultField'
import getAgentStatsByMeetingType from '../../utils/getAgentStatsByMeetingType'
import fieldTranslations from '../../utils/fieldTranslations'

const ChooseAgentEdit = (props) => {
	const classes = useStyles()
	const {
		setAgentFormActive,
		setConfirmBookingActive,
		agentFormActive,
		...raProps
	} = props

	return (
		<Drawer
			anchor="right"
			classes={{ paper: classes.drawer }}
			open={agentFormActive}
			onClose={() => setAgentFormActive(false)}
		>
			<Edit {...raProps} actions={null} title=" ">
				<SimpleForm toolbar={null}>
					<TitleBar
						titleText="Mahdolliset edustajat"
						setActive={setAgentFormActive}
					/>

					<FormDataConsumer>
						{({ formData }) => (
							<>
								<Typography>
									{
										fieldTranslations.customerType[
										formData.customer.customerType
										]
									}
								</Typography>
								<ChooseAgentField
									zoneId={formData.zoneId}
									onSelect={() => {
										setAgentFormActive(false)
										setConfirmBookingActive()
									}}
									record={formData}
								/>
							</>
						)}
					</FormDataConsumer>
				</SimpleForm>
			</Edit>
		</Drawer>
	)
}

export default ChooseAgentEdit

const ChooseAgentField = ({ record, zoneId, onSelect }) => {
	const [choices, setChoices] = useState([])
	useEffect(() => {
		GetZoneAgents(zoneId, setChoices, new Date(record.appointmentStartsAt))
	}, [
		zoneId,
		record.appointmentStartsAt,
		record.meetingType,
		record.locationId,
	])

	return (
		<>
			{choices
				? sortByBookingLocation(
					sortByOrderAmounts(choices, record.customer.customerType),
					record.locationId,
				).map((data, i) => (
					<AgentField
						key={i}
						agent={data}
						record={record}
						onSelect={onSelect}
					/>
				))
				: null}
		</>
	)
}

const sortByOrderAmounts = (agents, customerType) => {
	const sortingComparisonByType = {
		PRIVATE: comparePrivate,
		FARM: compareFarm,
		ORGANIZATION: compareOrganization,
		SOK: compareSOK,
	}[customerType];

	return agents.sort(sortingComparisonByType)
}

const comparePrivate = (b, a) => {
	return (
		a.orderedPrivate -
		(a.confirmedOwnLocal +
			a.confirmedOwnRemote +
			a.confirmedCompetitorLocal +
			a.confirmedCompetitorRemote +
			a.completedOwnLocal +
			a.completedOwnRemote +
			a.completedCompetitorRemote +
			a.completedCompetitorLocal -
			a.canceledOwnLocal -
			a.canceledOwnRemote -
			a.canceledCompetitorLocal -
			a.canceledCompetitorRemote) -
		(b.orderedPrivate -
			(b.confirmedOwnLocal +
				b.confirmedOwnRemote +
				b.confirmedCompetitorLocal +
				b.confirmedCompetitorRemote +
				b.completedOwnLocal +
				b.completedOwnRemote +
				b.completedCompetitorRemote +
				b.completedCompetitorLocal -
				b.canceledOwnLocal -
				b.canceledOwnRemote -
				b.canceledCompetitorLocal -
				b.canceledCompetitorRemote))
	)
}

const compareFarm = (b, a) => {
	return (
		a.orderedFarm -
		a.confirmedFarmLocal +
		a.confirmedFarmRemote +
		a.completedFarmLocal +
		a.completedFarmRemote -
		a.canceledFarmLocal -
		a.canceledFarmRemote -
		(b.orderedFarm -
			(b.confirmedFarmLocal +
				b.confirmedFarmRemote +
				b.completedFarmLocal +
				b.completedFarmRemote -
				b.canceledFarmLocal -
				b.canceledFarmRemote))
	)
}

const compareOrganization = (b, a) => {
	return (
		a.orderedCompany -
		(a.confirmedOrganizationLocal +
			a.confirmedOrganizationRemote +
			a.completedOrganizationLocal +
			a.completedOrganizationRemote -
			a.canceledOrganizationLocal -
			a.canceledOrganizationRemote) -
		(b.orderedCompany -
			(b.confirmedOrganizationLocal +
				b.confirmedOrganizationRemote +
				b.completedOrganizationLocal +
				b.completedOrganizationRemote -
				b.canceledOrganizationLocal -
				b.canceledOrganizationRemote))
	)
}

const compareSOK = (b, a) => {
	return (
		a.orderedSOK -
		(a.confirmedSOKLocal +
			a.confirmedSOKRemote +
			a.completedSOKLocal +
			a.completedSOKRemote -
			a.canceledSOKLocal -
			a.canceledSOKRemote) -
		(b.orderedSOK -
			(b.confirmedSOKLocal +
				b.confirmedSOKRemote +
				b.completedSOKLocal +
				b.completedSOKRemote -
				b.canceledSOKLocal -
				b.canceledSOKRemote))
	)
}

const sortByBookingLocation = (choices, locationId) => {
	return choices.sort((a, b) => {
		let aHasLocation = a.locations.some(
			(location) => location.id === locationId,
		)
		let bHasLocation = b.locations.some(
			(location) => location.id === locationId,
		)

		return bHasLocation - aHasLocation
	})
}

const AgentField = ({ record, agent, onSelect }) => {
	const classes = useStyles()

	const { locations } = agent
	const { customerType } = record.customer
	const isAgentOfMeetingLocation = locations.some(
		(location) => record.locationId === location.id,
	)

	const stats = getAgentStatsByMeetingType(agent, customerType) || {}

	return (
		<Card className={classes.agentCard}>
			<div
				className={
					isAgentOfMeetingLocation
						? classes.agentCardLocMark
						: classes.agentCardNoLoc
				}
			></div>
			<div>
				<div className={classes.resultWrapper}>
					<CardHeader
						title={agent.firstName + ' ' + agent.lastName}
						subheader={locations.map((location) => location.name).join(', ')}
					/>

					<CardActions className={classes.agentCardActions}>
						{agent.id !== record.agentId ? (
							<AgentChooseButton
								onSelect={onSelect}
								record={record}
								agentId={agent.id}
							/>
						) : (
							<AgentUnSelectButton record={record} />
						)}
					</CardActions>
				</div>

				<CardContent className={classes.content}>
					<div>
						<Labeled label={'Tilattu'}>
							<div className={classes.resultField}>
								<AssignmentIcon color="primary" fontSize="small" />
								<Typography className={classes.typoGraphy} variant="body2">
									{stats.ordered}
								</Typography>
							</div>
						</Labeled>
					</div>
					<ResultField
						label="Toteutuneet"
						localAmount={stats.completedLocal}
						remoteAmount={stats.completedRemote}
					/>
					<ResultField
						label="Vahvistetut"
						localAmount={stats.confirmedLocal}
						remoteAmount={stats.confirmedRemote}
					/>
					<ResultField
						label="Peruuntuneet"
						localAmount={stats.canceledLocal}
						remoteAmount={stats.canceledRemote}
					/>
				</CardContent>
			</div>
		</Card>
	)
}

const AgentChooseButton = ({ onSelect, agentId, record }) => {
	const classes = useStyles()
	const [choose, loading] = SetBookingAgent(record, agentId)

	const handleClick = () => {
		choose()
		onSelect()
	}

	return (
		<Button
			className={classes.button}
			color="primary"
			variant="contained"
			onClick={handleClick}
			disabled={loading}
		>
			Valitse
		</Button>
	)
}

const AgentUnSelectButton = ({ record }) => {
	const classes = useStyles()
	const [unSelect, { loading }] = RemoveBookingAgent(record)
	const handleClick = () => {
		unSelect()
	}
	return (
		<Button
			className={classes.button}
			color="secondary"
			onClick={handleClick}
			disabled={loading}
		>
			Peruuta
		</Button>
	)
}

const useStyles = makeStyles({
	drawer: {
		width: '50%',
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	titleBar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	agentChoiceInfoText: {
		fontSize: 14,
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontWeight: 'normal',
	},
	agentCard: {
		margin: 20,
	},
	agentCardContent: {
		display: 'flex',
	},
	agentCardLocMark: {
		height: '0.5em',
		width: '100%',
		backgroundColor: '#00aced',
	},
	agentCardNoLoc: {
		height: '0.5em',
		width: '100%',
	},
	agentCardContentCenter: {
		justifyContent: 'flex-end',
		margin: 'auto 0',
		marginLeft: 'auto',
	},
	agentCardActions: {
		marginLeft: 20,
		padding: 16,
		alignItems: 'flex-start',
		right: 0,
	},
	locationTypo: {
		marginRight: 5,
	},
	bookingLocTypo: {
		fontWeight: 'bold',
	},
	resultField: {
		display: 'flex',
		alignItems: 'center',
	},
	resultLocal: {
		display: 'flex',
		alignItems: 'center',
	},
	resultRemote: {
		marginLeft: 10,
		display: 'flex',
		alignItems: 'center',
	},
	typoGraphy: {
		marginLeft: 5,
	},
	resultWrapper: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
})
