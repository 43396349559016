import React from "react";
import { Edit } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  edit: {
    maxWidth: 1024,
    width: "100%",
    margin: "0 auto",
  },
});

export default (props) => {
  const classes = useStyles();

  return <Edit {...props} className={classes.edit} />;
};
