import React from 'react'
import StatusIcon from '../../components/StatusIcon'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
	statusField: {
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
	},
	statusText: {
		marginRight: 5,
	},
})

const statusLabels = {
	UNHANDLED: 'Käsittelemätön',
	CONFIRMED: 'Vahvistettu',
	COMPLETED: 'Toteutunut',
	CANCELED: 'Peruutettu',
	UNHANDLED_RETRIAL: 'Palautettu käsittelyyn',
	CANCELED_RETRIAL: 'Peruutettu - Palautettu käsittelyyn',
}

const StatusField = (props) => {
	const classes = useStyles()
	return (
		<Typography
			component="span"
			variant="body2"
			className={classes.statusField}
		>
			<span className={classes.statusText}>
				{props.record.archived ? 'Arkistoitu - ' : ''}
				{statusLabels[props.record.status] || ''}
			</span>
			<StatusIcon {...props} />
		</Typography>
	)
}

export default StatusField
