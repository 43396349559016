import * as React from 'react'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, useNotify } from 'ra-core'
import { Input } from './LoginInput'
import Axios from 'axios'

const sendSmsToPhone = async ({ phoneNumber, sessionId }) => {
	const response = await Axios.post(
		`${process.env.REACT_APP_API_URL}/users/sms`,
		{
			phoneNumber,
			sessionId,
		},
	)
	return response
}

const SmsForm = ({
	setLoginState,
	props,
	sessionId
}) => {
	const translate = useTranslate()
	const classes = useStyles(props)
	const notify = useNotify();

	const submit = async (values) => {
		const { phoneNumber } = values
		// if there is no phoneNumber on the user, add it via the input, otherwise from the userInformation
		try {
			const res = await sendSmsToPhone({
				phoneNumber,
				sessionId
			})

			if (res.statusText === 'Created') {
				setLoginState('verification-code')
			}
		} catch (e) {
			notify("Virhe kirjautumiskoodin lähettämisessä, onko puhelinnumero varmasti oikein?", { type: "error" })
		}

	}

	const validate = (values) => {
		const errors = {
			phoneNumber: undefined,
		}
		if (!values.phoneNumber) {
			errors.phoneNumber = translate('ra.validation.required')
		}
		return errors
	}

	return (
		<Form
			onSubmit={submit}
			validate={validate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.form}>
						<div className={classes.input}>
							<p>
								Käytä puhelinnumeroa <b>+358</b> koodilla. <br />
								Varmista, että puhelinnumero on oikein kirjoitettu ennen kuin
								lähetät. <br />
								Esimerkiksi: <b>+3581234567</b>
							</p>
							<Field
								autoFocus
								id="phoneNumber"
								name="phoneNumber"
								component={Input}
								label={'Puhelinnumero'}
							/>
						</div>
						<CardActions>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								className={classes.button}
							>
								Lähetä tekstiviestivahvistus
							</Button>
						</CardActions>
					</div>
				</form>
			)}
		/>
	)
}

const useStyles = makeStyles(
	(theme) => ({
		form: {
			padding: '0 1em 1em 1em',
		},
		input: {
			marginTop: '1em',
		},
		button: {
			width: '100%',
		},
		icon: {
			marginRight: theme.spacing(1),
		},
	}),
	{ name: 'RaLoginForm' },
)

export default SmsForm
