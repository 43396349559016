const reformatRecord = (data) => {
	return {
		name: data.name,
		id: data.id,
		zoneId: data.zoneId,
		businessHours: {
			id: data?.businessHours?.id,
			mon: [data.mon.opensAt, data.mon.closesAt],
			tue: [data.tue.opensAt, data.tue.closesAt],
			wed: [data.wed.opensAt, data.wed.closesAt],
			thu: [data.thu.opensAt, data.thu.closesAt],
			fri: [data.fri.opensAt, data.fri.closesAt],
			sat:
				data.sat && data.sat.opensAt && data.sat.closesAt
					? [data.sat.opensAt, data.sat.closesAt]
					: [],
			sun:
				data.sun && data.sun.opensAt && data.sun.closesAt
					? [data.sun.opensAt, data.sun.closesAt]
					: [],
		},
	}
}

export default reformatRecord
