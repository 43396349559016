import React, { useState } from 'react'
import {
	Datagrid,
	ReferenceField,
	Filter,
	downloadCSV,
	useInput,
} from 'react-admin'
import {
	TextField,
	DateField,
	SelectInput,
	CheckboxGroupInput,
	ReferenceArrayInput,
	List,
	Typography,
	ReferenceInput,
	TextInput,
} from '../components/overrides'
import RadioButtonField from '../components/RadioButtonField'
import StatusIcon from '../components/StatusIcon'
import AgentFullNameField from '../components/AgentFullNameField'
import SalesFullNameField from '../components/SalesFullNameField'
import ConfirmedByFullNameField from './bookingComponents/ConfirmedByFullNameField'
import MeetingTypeField from '../components/MeetingTypeField'
import jsonExport from 'jsonexport/dist'
import fieldTranslations, {
	mapTranslationsToArray,
} from '../utils/fieldTranslations'
import { Button, InputAdornment, makeStyles } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import fiLocale from 'date-fns/locale/fi'
import TooltipField from './bookingComponents/TooltipField'

const BookingList = (props) => {
	const { filter, zoneFilter, ...raProps } = props
	return (
		<List
			{...raProps}
			title=" Buukkaukset"
			bulkActionButtons={false}
			exporter={exporter}
			filter={zoneFilter}
			filterDefaultValues={{ archived: 'false' }}
			filters={
				<Filter style={{ display: 'flex', alignItems: 'center' }}>
					<TextInput source="q" label="Etsi asiakasta tai merkintää" />
					<ReferenceArrayInput label="Alue" source="zones" reference="zones" perPage={100} sort={{ field: "name", order: "ASC" }} />
					<SelectInput
						label="Tapaamistyyppi"
						source="meetingType"
						emptyText="Kaikki"
						choices={mapTranslationsToArray('meetingType')}
					/>
					<ReferenceInput
						label="Myyjä"
						source="createdBy.id"
						reference="users"
						allowEmpty={false}
						perPage={9999}
					>
						<SelectInput
							optionText={(user) => `${user.firstName} ${user.lastName}`}
							optionValue="id"
							allowEmpty={false}
						/>
					</ReferenceInput>
					<DateFilterInput label="Luontipäivä" source="createdOn" monthMode />
					<DateFilterInput label="Tapaamisaika" source="appointmentStartsAt" />
					<SelectInput
						source="archived"
						label="Arkistoidut"
						emptyText="Näytä"
						choices={[{ id: false, name: 'Piilota' }]}
					/>
					<CheckboxGroupInput
						label="Tila"
						source="status"
						choices={[
							{
								id: ['UNHANDLED', 'UNHANDLED_RETRIAL'],
								name: 'Käsittelemättömät',
							},
							{ id: ['CANCELED', 'CANCELED_RETRIAL'], name: 'Peruutetut' },
							{ id: 'CONFIRMED', name: 'Vahvistetut' },
							{ id: 'COMPLETED', name: 'Toteutuneet' },
						]}
						alwaysOn
					/>
				</Filter>
			}
		>
			<Datagrid rowClick="show">
				<StatusIcon label="Tila" addLabel={true} source="status" />
				<TextField source="customer.name" label="Asiakas" />
				<DateField
					label="Tapaamisaika"
					source="appointmentStartsAt"
					showTime={true}
				/>
				<RadioButtonField
					field="cancelReason"
					label="Peruutuksen syy"
					source="cancelDetails.cancelReason"
				/>
				<RadioButtonField
					field="customerType"
					label="Asiakastyyppi"
					addLabel={true}
					source="customer.customerType"
				/>
				<RadioButtonField
					field="invoiceType"
					label="Laskutustyyppi"
					addLabel={true}
					source="invoiceType"
				/>
				<ReferenceField
					label="Alue"
					source="zoneId"
					reference="zones"
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>

				<MeetingTypeField
					label="Tapaamistyyppi"
					addLabel={true}
					sortBy="meetingType"
				/>
				<ReferenceField
					label="Edustaja"
					source="agentId"
					reference="agents"
					link="show"
				>
					<AgentFullNameField />
				</ReferenceField>
				<SalesFullNameField label="Myyjä" />
				<ConfirmedByFullNameField label="Varaaja" />
				<TooltipField
					label="Info"
					addLabel={true}
					source="coordinatorEntries"
				/>
			</Datagrid>
		</List>
	)
}

/* lllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll */

const exporter = (bookings, fetchRelatedRecords) => {
	const locations = fetchRelatedRecords(bookings, 'locationId', 'locations')
	const agents = fetchRelatedRecords(bookings, 'agentId', 'agents')

	Promise.all([locations, agents]).then(([locations, agents]) => {
		const bookingsForExport = bookings.map((booking) => {
			const {
				customer,
				confirmedBy,
				appointmentStartsAt,
				invoiceType,
				status,
				agentId,
				locationId,
				customerSource,
			} = booking

			return {
				asiakkaan_nimi: customer.name,
				edustaja: agents[agentId]
					? `${agents[agentId].firstName} ${agents[agentId].lastName}`
					: '',
				tapaamisaika: new Date(appointmentStartsAt).toLocaleString('fi'),
				asiakastyyppi: fieldTranslations.customerType[customer.customerType],
				laskutustyyppi: fieldTranslations.invoiceType[invoiceType],
				tila: fieldTranslations.status[status],
				yrityksen_koko: customer.organizationSize
					? fieldTranslations.organizationSize[customer.organizationSize]
					: '',
				tapaamispaikka: locations[locationId]?.name,
				ajanvaraaja: confirmedBy
					? `${confirmedBy.firstName} ${confirmedBy.lastName}`
					: '',
				soittolista: fieldTranslations.customerSource[customerSource],
			}
		})

		jsonExport(
			bookingsForExport,
			{
				headers: [
					'asiakkaan_nimi',
					'edustaja',
					'tapaamisaika',
					'asiakastyyppi',
					'laskutustyyppi',
					'yrityksen_koko',
					'tapaamispaikka',
					'tila',
					'ajanvaraaja',
					'soittolista',
				], // order fields in the export
			},
			(err, csv) => {
				downloadCSV(csv, 'buukkaukset') // download as 'posts.csv` file
			},
		)
	})
}

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-input': {
			padding: 10.5,
			width: 100,
		},
	},
})

const DateFilterInput = (props) => {
	const [dayMode, setDayMode] = useState(props.monthMode)
	const classes = useStyles()
	const {
		input: { name, onChange, value },
	} = useInput(props)

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
			<DatePicker
				className={classes.root}
				name={name}
				value={value}
				label={props.label}
				format={dayMode ? 'dd.MM' : 'MM'}
				views={dayMode ? ['month', 'date'] : ['month']}
				invalidDateMessage={null}
				onChange={(value) => {
					onChange(
						dayMode
							? `${value.getFullYear()}-${value.getMonth() + 1
							}-${value.getDate()}`
							: `${value.getFullYear()}-${value.getMonth() + 1}`,
					)
				}}
				autoOk={true}
				inputVariant="outlined"
				InputProps={{
					endAdornment: (
						<InputAdornment>
							<Button
								onClick={() => setDayMode(!dayMode)}
								variant="outlined"
								size="small"
							>
								<Typography> {dayMode ? 'PÄIVÄ' : 'KK'} </Typography>
							</Button>
						</InputAdornment>
					),
				}}
			/>
		</MuiPickersUtilsProvider>
	)
}

export default BookingList
