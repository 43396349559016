// in src/MyLayout.js
import * as React from 'react'
import { Layout, usePermissions } from 'react-admin'
import Bar from './Bar'
import Drawer from './Drawer'

const CustomLayout = (props) => {
	const { loading, permissions } = usePermissions()

	if (!loading && !permissions.length) {
		return (
			<div style={{ padding: 20, backgroundColor: '#333', minHeight: '100vh' }}>
				{' '}
				{props.children}
			</div>
		)
	}

	return <Layout {...props} appBar={Bar} sidebar={Drawer} />
}
export default CustomLayout
