import { useQuery } from 'react-admin'

const GetZoneLocations = (zoneId) => {
	const { data } = useQuery({
		type: 'getManyReference',
		resource: 'locations',
		payload: {
			target: 'zoneId',
			id: zoneId,
			sort: { field: 'id', order: 'ASC' },
			pagination: { page: 1, perPage: 9999 },
			filter: {},
		},
	})
	return data
}

export default GetZoneLocations
