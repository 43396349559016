import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
	DateField,
	TextField,
	TextInput,
	SimpleForm,
} from '../../components/overrides'
import ActionField from './ActionField'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { useDataProvider } from 'react-admin'

const useStyles = makeStyles({
	historyContainer: {
		width: 460,
		marginLeft: '1rem',
	},
	tableCell: {
		padding: 10,
	},
	paper: {},
	typography: {
		padding: 20,
	},
	coordinatorEntries: {
		marginTop: 10,
		padding: 0,
	},
})

//TODO: Separate the coordinator entries
const HistoryField = (props) => {
	const dataProvider = useDataProvider()

	const classes = useStyles()

	const onSave = (values) => {
		dataProvider.update('bookings', {
			id: props.record.id,
			data: values,
		})
	}

	return (
		<div className={classes.historyContainer}>
			<TableContainer component={Paper}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell className={classes.tableCell}>Aika</TableCell>
							<TableCell className={classes.tableCell} align="left">
								Toiminta
							</TableCell>
							<TableCell className={classes.tableCell} align="left">
								Tekijä
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.record &&
							props.record.bookingHistory &&
							props.record.bookingHistory.map((_, i) => {
								//Show latest first
								const iReverse = props.record.bookingHistory.length - i - 1
								return (
									<TableRow key={iReverse}>
										<TableCell className={classes.tableCell}>
											<DateField
												label="Aika"
												source={'bookingHistory[' + iReverse + '].time'}
												showTime={true}
												{...props}
											/>
										</TableCell>
										<TableCell className={classes.tableCell} align="left">
											<ActionField
												label="Toiminta"
												addLabel={true}
												source={'bookingHistory[' + iReverse + '].action'}
												action={props.record.bookingHistory[iReverse].action}
												status={props.record.bookingHistory[iReverse].status}
												retrialReference={
													props.record.bookingHistory[iReverse].retrialReference
												}
												{...props}
											/>
										</TableCell>
										<TableCell className={classes.tableCell} align="left">
											<TextField
												label="Tekijä"
												source={'bookingHistory[' + iReverse + '].by.firstName'}
												{...props}
											/>{' '}
											<TextField
												source={'bookingHistory[' + iReverse + '].by.lastName'}
												{...props}
											/>
										</TableCell>
									</TableRow>
								)
							})}
					</TableBody>
				</Table>
			</TableContainer>

			<Paper className={classes.coordinatorEntries}>
				<SimpleForm initialValues={props.record} save={onSave}>
					<TextInput source="coordinatorEntries" label="Merkinnät" multiline />
				</SimpleForm>
			</Paper>
		</div>
	)
}

export default HistoryField
