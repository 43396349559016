import React from 'react'
import { TextField, List } from '../components/overrides'
import { Datagrid } from 'react-admin'
import UnhandledNumber from './UnhandledNumber'
import { ListActions } from '../components/overrides'

export default (props) => {
	return (
		<List
			{...props}
			title="Alueet"
			bulkActionButtons={false}
			actions={<ListActions />}
		>
			<Datagrid rowClick="show">
				<TextField label="Nimi" source="name" />
				<UnhandledNumber label="Käsittelemättömät" addLabel={true} />
			</Datagrid>
		</List>
	)
}
