import React from 'react'
import { DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    dialogContent: {
        '&.MuiDialogContent-root': {
            padding: '20px',
        }
	},
})

export default ({ children, ...props }) => {
    const classes = useStyles()
	return (
		<DialogContent
            className={classes.dialogContent}
            {...props}
        >
            {children}
		</DialogContent>
	)
}