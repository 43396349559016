import React from 'react'
import { FunctionField } from 'react-admin'
import Badge from '@material-ui/core/Badge'

const UnhandledNumber = (props) => {
	const count = props.record.unhandledBookings.length

	return (
		<FunctionField
			{...props}
			render={() => <Badge badgeContent={count} color="primary"></Badge>}
		/>
	)
}

export default UnhandledNumber
