import React from 'react'
import {
	Card,
	CardContent,
	Typography,
	List,
	ListItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
	container: {
		width: '100%',
		margin: '0 auto',
		justifyContent: 'center',
	},
	card: {
		padding: '2em',
	},
})

//TODO: Yhteystietojen lisääminen

const SystemInfo = () => {
	const classes = useStyles()
	return (
		<Card className={classes.card}>
			<CardContent>
				<Typography variant="h5" paragraph>
					Tietoa järjestelmästä
				</Typography>
				<hr />
				<Typography variant="h6">Kirjautumistiedot</Typography>
				<Typography variant="body1" paragraph>
					Selaimeen tallennetut kirjautumistiedot poistuvat joka yö.
				</Typography>
				<Typography variant="h6">Varmuuskopiointi</Typography>
				<Typography variant="body1" paragraph>
					Järjestelmä luo tiedoistaan varmuuskopion joka yö.
				</Typography>
				<Typography variant="h6">Buukkausten peruminen</Typography>
				<Typography variant="body1">
					Edustaja voi peruuttaa buukkauksen kuukauden sisällä tapaamisen
					ajankohdasta. Myös koordinaattori voi peruuttaa tapaamisen yksittäisen
					buukkauksen näkymässä.
				</Typography>
				<Typography variant="body1" paragraph>
					Kuluvan kuun myyntitulokset kerätään talteen kuun vaihtuessa.
				</Typography>
				<Typography variant="h6">Buukkauksien arkistointi</Typography>
				<Typography variant="body1">
					Järjestelmä arkistoi peruutetut buukkaukset automaattisesti, kun
					buukkaukseen merkitystä tapaamisesta on kulunut kuukausi.
					Käsittelemättömiä buukkauksia ei arkistoida automaattisesti.
				</Typography>
				<Typography variant="body1" paragraph>
					Kuluvan kuun toteutuneet sekä peruutetut mutta käsittelyyn palautetut
					buukkaukset arkistoidaan kuun vaihtuessa.
				</Typography>
				<Typography variant="h6">Buukkauksien poistaminen</Typography>
				<Typography variant="body1" paragraph>
					Järjestelmä poistaa buukkauksen automaattisesti, kun buukkauksen
					luontiajankohdasta on kulunut 3 kuukautta. Näistä jää ainoastaan
					myyntitulokset talteen.
				</Typography>
				<Typography variant="h6">Tekninen tuki</Typography>
				<Typography variant="body1">
					Virheen sattuessa kerro ajankohta ja vaihe vaiheelta mitä tapahtui.
					Kuvakaappaus auttaa ongelman ratkaisemista.
				</Typography>

				<Typography variant="body1">Tuen yhteystiedot:</Typography>

				<Typography variant="body1" paragraph>
					<List dense>
						<ListItem>Sähköposti: lauri.meltti@holda.fi</ListItem>
					</List>
				</Typography>
			</CardContent>
		</Card>
	)
}

export default SystemInfo
