import React from 'react'
import Typography from '@material-ui/core/Typography'

const meetingTypeLabels = {
	WEB: 'Verkko',
	PHONE: 'Puhelin',
	LOCATION: 'Tapaamispaikka',
	CUSTOMERS_LOCATION: 'Asiakkaan luona',
}

const MeetingTypeField = (props) => (
	<Typography variant="body2">
		{props.record && meetingTypeLabels[props.record.meetingType]}
	</Typography>
)

export default MeetingTypeField
