import axios from 'axios'

const GetAllZonesDetails = (setResults) => {
	const path = `${process.env.REACT_APP_API_URL}/bookings/results`

	const token = localStorage.getItem('jwt')
	axios
		.get(path, {
			headers: { Authorization: `Bearer: ${token}` },
		})
		.then((response) => {
			setResults(response.data)
		})
		.catch((error) => {})
}

export default GetAllZonesDetails
