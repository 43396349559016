import React from 'react'
import { SelectArrayInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	selectArrayInput: {},
})

export default (props) => {
	const classes = useStyles()

	return (
		<SelectArrayInput
			{...props}
			className={classes.selectArrayInput}
			variant="outlined"
			fullWidth
		/>
	)
}
