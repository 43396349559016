import * as React from 'react'
import { FormDataConsumer } from 'react-admin'
import { TextInput, SimpleForm, NumberInput } from '../components/overrides'
import {
	validateEmail,
	validateNumber,
	validateText,
} from '../utils/FormValidators'
import { useForm } from 'react-final-form'
import {
	ReferenceInput,
	SelectInput,
	SelectArrayInput,
} from '../components/overrides'
import { validateZone, validateLocation } from '../utils/FormValidators'
import { GetZoneLocations } from '../effects'

const AgentForm = ({ title, ...props }) => {
	return (
		<SimpleForm title={title} {...props}>
			<TextInput label="Etunimi" source="firstName" validate={validateText} />
			<TextInput label="Sukunimi" source="lastName" validate={validateText} />
			<TextInput label="Sähköposti" source="email" validate={validateEmail} />
			<FormDataConsumer>
				{({ formData }) => <ZoneLocationInputs {...formData} />}
			</FormDataConsumer>
			<NumberInput
				label="Kotitalous (kk-tilaus)"
				source="orderedPrivate"
				validate={validateNumber}
			/>
			<NumberInput
				label="Yritys (kk-tilaus)"
				source="orderedCompany"
				validate={validateNumber}
			/>
			<NumberInput
				label="Maatila (kk-tilaus)"
				source="orderedFarm"
				validate={validateNumber}
			/>
			<NumberInput
				label="SOK (kk-tilaus)"
				source="orderedSOK"
				validate={validateNumber}
			/>
		</SimpleForm>
	)
}

const ZoneLocationInputs = ({ formData, ...rest }) => {
	const form = useForm()
	return (
		<React.Fragment>
			<ReferenceInput
				label="Alue"
				source="zoneId"
				reference="zones"
				onChange={() => form.change('locationIds', null)}
				validate={validateZone}
				perPage={100}
			>
				<SelectInput sortAlphabetically />
			</ReferenceInput>
			<SelectArrayInput
				label="Tapaamispaikka"
				source="locationIds"
				validate={validateLocation}
				choices={GetZoneLocations(rest.zoneId)}
				sortAlphabetically
			/>
		</React.Fragment>
	)
}

export default AgentForm
