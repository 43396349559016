import * as React from 'react'
import { TextField } from '../components/overrides'
import { makeStyles } from '@material-ui/core/styles'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
	businessHoursContainer: {
		marginBottom: 30,
		marginTop: '1rem',
		maxWidth: 300,
		maxHeight: 350,
		padding: 20,
	},
	tableCell: {
		fontWeight: 'bold',
		padding: 10,
	},
	tableLine: {
		padding: 0,
	},
})

const TableRowDayHours = (props) => {
	const classes = useStyles()
	return (
		<TableRow>
			<TableCell className={classes.tableCell}>{props.text}</TableCell>
			<TableCell className={classes.tableCell} align="right">
				<TextField
					record={props.record}
					source={'businessHours.' + props.day + '[0]'}
				/>
			</TableCell>
			<TableCell className={classes.tableLine} align="right">
				<Typography>-</Typography>
			</TableCell>
			<TableCell className={classes.tableCell} align="right">
				<TextField
					record={props.record}
					source={'businessHours.' + props.day + '[1]'}
				/>
			</TableCell>
		</TableRow>
	)
}

const BusinessHoursField = ({ style, ...props }) => {
	const classes = useStyles()
	return (
		<TableContainer
			component={Paper}
			className={classes.businessHoursContainer}
			style={style}
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={classes.tableCell}>Aukioloajat</TableCell>
						<TableCell className={classes.tableCell} align="right"></TableCell>
						<TableCell className={classes.tableCell} align="right"></TableCell>
						<TableCell className={classes.tableCell} align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRowDayHours text="Ma" day="mon" {...props} />
					<TableRowDayHours text="Ti" day="tue" {...props} />
					<TableRowDayHours text="Ke" day="wed" {...props} />
					<TableRowDayHours text="To" day="thu" {...props} />
					<TableRowDayHours text="Pe" day="fri" {...props} />
					<TableRowDayHours text="La" day="sat" {...props} />
					<TableRowDayHours text="Su" day="sun" {...props} />
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default BusinessHoursField
