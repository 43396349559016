import * as React from 'react'
import { Field, Form } from 'react-final-form'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, useSafeSetState } from 'ra-core'
import CardActions from '@material-ui/core/CardActions'
import { Input } from './LoginInput'
import { useLogin, useNotify } from 'ra-core'

const VerificationForm = ({
	props,
	sessionId,
}) => {
	const [loading, setLoading] = useSafeSetState(false)
	const translate = useTranslate()
	const classes = useStyles(props)
	const login = useLogin()
	const notify = useNotify();

	const submit = async (values) => {
		const { code } = values
		setLoading(true)
		const bodyParams = {
			code,
			sessionId,
		}
		try {
			await login(bodyParams)
		} catch (e) {
			notify("Virhe kirjautumisessa koodilla, onko koodi varmasti oikein?", { type: "error" })
		}

		setLoading(false)

	}

	const validate = (values) => {
		const errors = {
			code: undefined,
		}
		if (!values.code) {
			errors.code = translate('ra.validation.required')
		}
		return errors
	}

	return (
		<Form
			onSubmit={submit}
			validate={validate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.form}>
						<label>
							Jos et saa koodia, päivitä sivu ja kirjaudu uudelleen.
						</label>
						<div className={classes.input}>
							<Field
								autoFocus
								id="code"
								name="code"
								component={Input}
								label={'Koodi'}
							/>
							<CardActions>
								<Button
									variant="contained"
									type="submit"
									color="primary"
									className={classes.button}
								>
									{loading && (
										<CircularProgress
											className={classes.icon}
											size={18}
											thickness={2}
										/>
									)}
									{translate('ra.auth.sign_in')}
								</Button>
							</CardActions>
						</div>
					</div>
				</form>
			)}
		/>
	)
}

const useStyles = makeStyles(
	(theme) => ({
		form: {
			padding: '0 1em 1em 1em',
		},
		input: {
			marginTop: '1em',
		},
		button: {
			width: '100%',
		},
		icon: {
			marginRight: theme.spacing(1),
		},
	}),
	{ name: 'RaLoginForm' },
)
export default VerificationForm
