import React from 'react'
import { TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	textField: {
		width: '100%',
	},
})

export const Field = (props) => {
	const classes = useStyles()

	return <TextField {...props} className={classes.textField} />
}

Field.defaultProps = {
	addLabel: true,
}

export default Field
