import React from "react";
import { DateTimeInput } from '../../components/overrides';
import { useForm } from 'react-final-form'
  
  export default (props) => {
    const form = useForm()
  
    return (
      <DateTimeInput
        {...props}
        validate={(value) => {
          if (!value) {
            return "Anna sovittu aika."
          } else if (new Date(value).getMinutes() % 15 !== 0) {
            return "Anna aika vartin tarkkuudella"
          } else {
            return undefined
          }
        }}        
        onChange={(event) => {
          let milliseconds = new Date(event.target.value).getTime()
          let startTime = new Date(milliseconds)
          let endTime = new Date(milliseconds + 1000 * 60 * 60)
          form.change('appointmentStartsAt', new Date(startTime))
          form.change('appointmentEndsAt', new Date(endTime))

        }}
      />
    )
  };