import React, { useState, useRef } from 'react'
import {
	TabbedShowLayout,
	ShowController,
	ShowView,
	ReferenceManyField,
} from 'react-admin'
import GroupIcon from '@material-ui/icons/Group'
import LocationIcon from '@material-ui/icons/LocationCity'
import BookIcon from '@material-ui/icons/Book'
import LocationTabContent from './LocationTabContent'
import AgentTabContent from './AgentTabContent'
import BookingList from '../bookings/BookingList'
import { Tab } from '../components/overrides'
import { useReactToPrint } from 'react-to-print'
import AgentReportPanel from './AgentReportPanel'

export default (props) => {
	const [agents, setAgents] = useState()
	const [selectedTime, setSelectedTime] = useState(new Date())
	const componentRef = useRef()
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Edustajaraportti',
		pageStyle: '',
	})
	return (
		<ShowController {...props}>
			{(controllerProps) => (
				<ShowView
					{...props}
					{...controllerProps}
					title={controllerProps.record && controllerProps.record.name}
				>
					<TabbedShowLayout>
						<Tab
							label="Buukkaukset"
							icon={<BookIcon color="primary" />}
							path=""
						>
							<ReferenceManyField
								reference="bookings"
								target="zoneId"
								addLabel={false}
							>
								<BookingList
									zoneFilter={
										controllerProps.record && {
											zoneId: controllerProps.record.id,
										}
									}
								/>
							</ReferenceManyField>
						</Tab>
						<Tab
							label="Tapaamispaikat"
							icon={<LocationIcon color="primary" />}
							path="locations"
						>
							<LocationTabContent />
						</Tab>
						<Tab
							icon={<GroupIcon color="primary" />}
							label={'Edustajat'}
							path="agents"
							title={
								<AgentReportPanel
									selectedTime={selectedTime}
									setSelectedTime={setSelectedTime}
									agents={agents}
									setAgents={setAgents}
									handlePrint={handlePrint}
									record={controllerProps.record}
								/>
							}
						>
							<AgentTabContent
								selectedTime={selectedTime}
								agents={agents}
								componentRef={componentRef}
							/>
						</Tab>
					</TabbedShowLayout>
				</ShowView>
			)}
		</ShowController>
	)
}
