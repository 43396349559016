import React from 'react'
import { List } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	style: {
		'& .MuiToolbar-root': {
			display: 'flex',
			padding: 0,
			alignItems: 'center',
		},
	},
})

export default (props) => {
	const classes = useStyles()

	return <List {...props} className={classes.style} />
}
