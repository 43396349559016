import React from 'react'
import { UserMenu, MenuItemLink } from 'react-admin'
import SettingsIcon from '@material-ui/icons/Settings'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
	menuItemLink: {
		marginBottom: '1em',
	},
})

const MyUserMenu = (props) => {
	const classes = useStyles()
	return (
		<UserMenu label={'Käyttäjä'} {...props}>
			<MenuItemLink
				className={classes.menuItemLink}
				to="/me"
				primaryText="Käyttäjätiedot"
				leftIcon={<SettingsIcon />}
			/>
			<MenuItemLink
				className={classes.menuItemLink}
				to="/system-info"
				primaryText="Tietoa järjestelmästä"
				leftIcon={<InfoIcon />}
			/>
		</UserMenu>
	)
}

export default MyUserMenu
