import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import fiLocale from 'date-fns/locale/fi'
import { makeStyles } from '@material-ui/core/styles'
import GetZoneAgents from '../effects/GetZoneAgents'
import { Typography } from '../components/overrides'

const useStyles = makeStyles({
	actionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: 30,
		margin: 16,
	},
	actionWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	printButton: {
		maxWidth: 200,
		justifyContent: 'left',
		marginRight: '2em',
	},
	datePicker: {
		maxWidth: 200,
		marginRight: '0.5em',
	},
	reportDetailContainer: {
		marginBottom: '1em',
		display: 'flex',
		justifyContent: 'space-between',
	},
	monthYearContainer: {
		display: 'flex',
	},
	month: {
		paddingRight: '0.5em',
	},
})

const PrintReportButton = ({ handlePrint, isDisabled }) => {
	const classes = useStyles()
	return (
		<Button
			className={classes.printButton}
			onClick={handlePrint}
			color="primary"
			disabled={isDisabled}
		>
			Tulosta Raportti
		</Button>
	)
}

const AgentReportPanel = (props) => {
	useEffect(() => {
		GetZoneAgents(props.record.id, props.setAgents, props.selectedTime)
	}, [props.selectedTime, props.record.id, props.setAgents])

	const classes = useStyles()
	return (
		<div className={classes.actionContainer}>
			<Typography variant="h6">Edustaja raportti</Typography>
			<div className={classes.actionWrapper}>
				<PrintReportButton
					handlePrint={props.handlePrint}
					isDisabled={props.agents !== undefined ? false : true}
				/>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
					<DatePicker
						className={classes.datePicker}
						variant="inline"
						openTo="year"
						views={['year', 'month']}
						helperText="Valitse vuosi ja kuukausi"
						value={props.selectedTime}
						onChange={() => null}
						onMonthChange={(time) => {
							props.setAgents(undefined)
							props.setSelectedTime(time)
						}}
						autoOk={true}
					/>
				</MuiPickersUtilsProvider>
			</div>
		</div>
	)
}

export default AgentReportPanel
