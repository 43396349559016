import React from 'react'
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  referenceArrayInput: {
    width: '100%',
  },
})

export default (props) => {
  const classes = useStyles()

  return <ReferenceArrayInput {...props} className={classes.referenceArrayInput}>
    <AutocompleteArrayInput variant="outlined" />
  </ReferenceArrayInput>
}
