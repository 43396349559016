import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

const ActionField = (props) => {
	const actionLabels = {
		CREATED:
			props.retrialReference !== null ? (
				<span>
					Luotu palautetun pohjalta:{' '}
					<Link to={`/bookings/${props.retrialReference}/show`}>
						Näytä alkuperäinen
					</Link>
				</span>
			) : (
				'Luotu'
			),
		CHECKED: 'Luottotiedot tarkistettu',
		CONFIRMED: 'Vahvistettu',
		CANCELED: 'Peruutettu',
		UPDATED: 'Muokattu',
		RETRIALED: 'Palautettu käsittelyyn',
		PREPARED: 'Edustaja valittu',
		CANCELED_BY_AGENT: 'Peruutettu edustajan toimesta',
		CANCELED_RETRIAL: (
			<span>
				Palautettu käsittelyyn:{' '}
				<Link to={`/bookings/${props.retrialReference}/show`}>Näytä uusi </Link>
			</span>
		),
		ARCHIVED: 'Arkistoitu',
	}
	return <Typography variant="body2">{actionLabels[props.action]}</Typography>
}

export default ActionField
