import React, { useState } from 'react'
import {
	ShowController,
	ShowView,
	SimpleShowLayout,
	ReferenceField,
} from 'react-admin'
import {
	CheckCreditsEdit,
	ChooseAgentEdit,
	CancelBooking,
	ConfirmBooking,
	RetrialDialog,
	ArchiveBooking,
	MeetingDateTimeEdit,
} from './ActionDialogs'
import BookingShowActions from './BookingShowActions'
import { TextField, DateField } from '../components/overrides'
import RadioButtonField from '../components/RadioButtonField'
import MeetingTypeField from '../components/MeetingTypeField'
import HistoryField from './bookingComponents/HistoryField'
import SectionDivider from '../components/SectionDivider'
import StatusField from './bookingComponents/StatusField'
import ListField from './bookingComponents/ListField'
import AgentFullNameField from '../components/AgentFullNameField'

// import CoordinatorEntries from '../components/CoordinatorEntries'
//TODO: clean up
const BookingShow = (props) => {
	// used to open/close dialogs/drawers
	const [activeDialog, setActiveDialog] = useState(null)
	const resetDialog = () => setActiveDialog(null)

	return (
		<React.Fragment>
			<ShowController {...props}>
				{(controllerProps) => {
					return (
						<ShowView
							title="Buukkaus"
							actions={
								props.permissions?.includes('SALES') ? null : controllerProps
									.record?.archived === true ? null : (
									<BookingShowActions onDialogSelect={setActiveDialog} />
								)
							}
							aside={<HistoryField />}
							{...props}
							{...controllerProps}
						>
							<SimpleShowLayout>
								<StatusField label="Tila" addLabel={true} />
								{controllerProps.record?.status === 'CANCELED' ||
									controllerProps.record?.status === 'CANCELED_RETRIAL' ? (
									<SectionDivider>Peruutuksen tiedot</SectionDivider>
								) : (
									''
								)}
								{controllerProps.record?.cancelDetails?.cancelType && (
									<RadioButtonField
										field="cancelType"
										label="Peruutuksen muoto"
										source="cancelDetails.cancelType"
										addLabel={true}
									/>
								)}
								{controllerProps.record?.cancelDetails?.cancelReason ? (
									<RadioButtonField
										field="cancelReason"
										label="Peruutuksen syy"
										source="cancelDetails.cancelReason"
										addLabel={true}
									/>
								) : (
									''
								)}

								{controllerProps.record?.cancelDetails?.cancelReason ===
									'OTHER' && (
										<TextField
											label="Mikä syy?"
											source="cancelDetails.reasonDetails"
											addLabel={true}
										/>
									)}
								{controllerProps.record?.cancelDetails?.otherDetails &&
									controllerProps.record?.cancelDetails?.otherDetails !==
									'' && (
										<TextField
											label="Lisätietoja"
											source="cancelDetails.otherDetails"
											addLabel={true}
										/>
									)}
								<SectionDivider>Tapaamistiedot</SectionDivider>
								<ReferenceField
									label="Valittu edustaja"
									source="agentId"
									reference="agents"
									link="show"
								>
									<AgentFullNameField />
								</ReferenceField>
								<MeetingTypeField label="Tapaamistyyppi" addLabel={true} />
								<ReferenceField
									label="Alue"
									source="zoneId"
									reference="zones"
									link="show"
									perPage={100}
								>
									<TextField source="name" />
								</ReferenceField>
								<ReferenceField
									label="Tapaamispaikka"
									source="locationId"
									reference="locations"
									link="show"
								>
									<TextField source="name" />
								</ReferenceField>
								{controllerProps.record?.appointmentStartsAt && (
									<DateField
										label="Sovittu aika"
										source="appointmentStartsAt"
										showTime={true}
										addLabel={true}
									/>
								)}
								<RadioButtonField
									label="Laskutustyyppi"
									addLabel={true}
									field="invoiceType"
								/>

								<SectionDivider>Asiakas</SectionDivider>
								<RadioButtonField
									label="Asiakastyyppi"
									addLabel={true}
									field="customerType"
								/>
								<TextField
									label="Nimi"
									source="customer.name"
									addLabel={true}
								/>
								<TextField
									label="Osoite"
									source="customer.address"
									addLabel={true}
								/>
								<TextField
									label="Puhelinnumero"
									source="customer.gsm"
									addLabel={true}
								/>
								<TextField
									label="Sähköposti"
									source="customer.email"
									addLabel={true}
								/>
								{controllerProps.record?.customer.customerType === 'PRIVATE' ||
									controllerProps.record?.customer.farmCustomerType ? null : (
									<RadioButtonField
										label="Yrityksen koko"
										addLabel={true}
										field="organizationSize"
									/>
								)}
								{controllerProps.record?.customer.customerType === 'PRIVATE' ||
									controllerProps.record?.customer.farmCustomerType ? null : (
									<RadioButtonField
										label="Yrityksen liikevaihto"
										addLabel={true}
										field="organizationRevenue"
									/>
								)}
								{controllerProps.record?.customer.customerType === 'PRIVATE' ||
									controllerProps.record?.customer.farmCustomerType ? (
									<DateField
										label="Syntymäaika"
										source="customer.birthday"
										addLabel={true}
									/>
								) : (
									<TextField
										label="Y-tunnus"
										source="customer.businessId"
										addLabel
									/>
								)}
								<SectionDivider>Laskutustiedot</SectionDivider>
								<RadioButtonField
									label="Asiakkaan luottotiedot"
									addLabel={true}
									field="creditCheckAllowed"
								/>

								{controllerProps.record?.customer.customerType === 'PRIVATE' ||
									controllerProps.record?.customer.farmCustomerType ? (
									<TextField
										label="Talouden vakuutettavat henkilöt"
										source="amountToBeInsured"
										addLabel={true}
									/>
								) : null}

								<ListField
									label="Vakuutusyhtiöt, joista vakuutuksia"
									source="insuranceCompanies"
								/>
								{controllerProps.record?.insuranceCompanies?.find(
									(item) => item !== 'LähiTapiola' && item !== 'Turva',
								) ? (
									<ListField
										label="Mitä erilaisia vakuutuksia löytyy nyt kilpailijoilta (muu kuin LähiTapiola tai Turva)"
										source="insurancesFromCompetitors"
									/>
								) : null}

								<SectionDivider>Muut</SectionDivider>

								<TextField label="Vapaa sana" source="notes" addLabel={true} />
								{controllerProps.record?.customer.customerType === 'PRIVATE' ||
									controllerProps.record?.customer.farmCustomerType ? (
									<RadioButtonField
										label="S-etukortti"
										addLabel={true}
										field="bonusCard"
									/>
								) : null}

								<RadioButtonField
									label="Mitä soittolistaa"
									addLabel={true}
									field="customerSource"
								/>
							</SimpleShowLayout>
						</ShowView>
					)
				}}
			</ShowController>
			<CheckCreditsEdit
				{...props}
				setCreditsFormActive={resetDialog}
				creditFormActive={activeDialog === 'credits'}
				setAgentFormActive={() => setActiveDialog('agent')}
			/>
			<ChooseAgentEdit
				{...props}
				setAgentFormActive={resetDialog}
				agentFormActive={activeDialog === 'agent'}
				setConfirmBookingActive={() => setActiveDialog('confirm')}
			/>
			<CancelBooking
				{...props}
				setCancelBookingActive={resetDialog}
				cancelBookingActive={activeDialog === 'cancel'}
			/>
			<RetrialDialog
				retrialId={props.id}
				setRetrialDialogActive={resetDialog}
				open={activeDialog === 'retrial'}
			/>
			<ConfirmBooking
				{...props}
				setConfirmBookingActive={resetDialog}
				confirmBookingActive={activeDialog === 'confirm'}
			/>
			<ArchiveBooking
				{...props}
				setArchiveDialogActive={resetDialog}
				archiveDialogActive={activeDialog === 'archive'}
			/>
			<MeetingDateTimeEdit
				{...props}
				setMeetingDateTimeActive={resetDialog}
				meetingDateTimeFormActive={activeDialog === 'meetingTime'}
			/>
		</React.Fragment>
	)
}

export default BookingShow
