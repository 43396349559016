import * as React from 'react'
import TextField from '@material-ui/core/TextField'

export const Input = ({
	meta: { touched, error }, // eslint-disable-line react/prop-types
	input: inputProps, // eslint-disable-line react/prop-types
	...props
}) => (
	<TextField
		error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
	/>
)
