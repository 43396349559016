import * as React from 'react'
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import UserMenu from './UserMenu'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	spacer: {
		flex: 1,
	},
	img: {
		width: 80,
	},
})

const CustomBar = (props) => {
	const classes = useStyles()
	return (
		<AppBar {...props} userMenu={<UserMenu />}>
			<Typography
				variant="h6"
				color="inherit"
				className={classes.title}
				id="react-admin-title"
			/>
			<Link to={'/'}>
				<img src="/loco.svg" className={classes.img} alt="logo" />
			</Link>
			<span className={classes.spacer} />
		</AppBar>
	)
}

export default CustomBar
