import React from 'react'
import { Typography } from '@material-ui/core'

//TODO: remove text prop and take text as children

const CustomTitle = ({ addRecordNameInFront, record, text, variant }) => {
	const headerText = addRecordNameInFront ? record.name + text : text
	return (
		<Typography variant={variant ? variant : 'h5'} paragraph>
			{headerText}
		</Typography>
	)
}

export default CustomTitle
