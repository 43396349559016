import React from 'react'
import { Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Typography } from '../../components/overrides'

const TooltipIcon = (props) => {
	const coordinatorEntries = props.record.coordinatorEntries
	return (
		<Tooltip title={<Typography>{coordinatorEntries}</Typography>}>
			<InfoIcon
				color="primary"
				style={{ fontSize: 20, color: coordinatorEntries ? '#003665' : '#999' }}
			/>
		</Tooltip>
	)
}

export default TooltipIcon
