import fieldTranslations from './fieldTranslations'

//TODO: Difficult to understand & do changes

export default (record, SELF_URL) => {
	console.log(record)
	const isOrganization = record.customer?.customerType === 'ORGANIZATION'
	const isPrivate = record.customer?.customerType === 'PRIVATE'
	const isPrivateFarm =
		record.customer?.customerType === 'FARM' &&
		record.customer?.farmCustomerType === 'PRIVATE'
	const isOrganizationFarm =
		record.customer?.customerType === 'FARM' &&
		record.customer?.farmCustomerType === 'ORGANIZATION'

	const isSOK = record.customer?.customerType === 'SOK';
	return `Tapaamistyyppi: ${record.meetingType ? fieldTranslations.meetingType[record.meetingType] : '-'
		}

Asiakastyyppi: ${isOrganization
			? 'Yritys'
			: isPrivate
				? 'Kotitalous'
				: isPrivateFarm
					? 'Yksityinen maatila'
					: isOrganizationFarm
						? 'Yritysmaatila'
						: '-'
		}

Asiakkaan nimi: ${record.customer?.name ? record.customer.name : '-'}

Osoite: ${record.customer?.address ? record.customer.address : '-'}

${isPrivate || isOrganization ? `Sähköposti: ${record.customer?.email}` : ''}

Puhelinnumero: ${record.customer?.gsm ? record.customer.gsm : '-'}

${isOrganization
			? `Yrityksen koko: ${fieldTranslations.organizationSize[record?.customer?.organizationSize]
			}
			
Y-tunnus: ${record.customer?.businessId ? record.customer.businessId : '-'}`
			: '-'
		}

Missä yhtiössä vakuutuksia: ${record.insuranceCompanies.length > 0
			? record.insuranceCompanies.join(', ')
			: 'Ei ole'
		}

Mitä vakuutuksia kilpailijalla: ${record.insurancesFromCompetitors.length > 0
			? record.insurancesFromCompetitors.join(', ')
			: 'Ei ole'
		}

${isPrivate || isPrivateFarm || isSOK
			? `Syntymäaika: ${record.customer?.birthday ? record.customer.birthday : '-'
			}

Talouden vakuutettavat henkilöt: ${record.amountToBeInsured || '-'}

${isPrivate
				? `Laskutustyyppi: ${fieldTranslations.invoiceType[record.invoiceType]}`
				: ''
			}`
			: isOrganization || isOrganizationFarm
				? `Yrityksen liikevaihto: ${record.customer?.organizationRevenue
					? fieldTranslations.organizationRevenue[
					record.customer.organizationRevenue
					]
					: '-'
				}`
				: ''
		}

Jos haluat antaa tapaamisesta palautetta tai ilmoittaa tapaamisen siirtyneen uuteen ajankohtaan, voit se tehdä tässä osoitteessa:
https://docs.google.com/forms/d/e/1FAIpQLScnp7CrZyEZ9-lfRAYCHrhtY-jqZGVHJ-guewsG8JowHXYH7g/viewform?vc=0&c=0&w=1

Buukkauksen peruutus osoitteessa: 
${SELF_URL}/#/cancel-booking/${record.id}/${record.cancelToken} `
}
