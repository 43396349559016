import React from 'react'
import { FunctionField } from './overrides'
import fieldTranslations from '../utils/fieldTranslations'

const RadioButtonField = (props) => {
	const field = props.field

	let sourceValue =
		props.record && props.record.customer[field]
			? props.record.customer[field]
			: props.record[field] || props.record.cancelDetails?.[field]

	if (props.record[field] === false) sourceValue = false
	if (!sourceValue && sourceValue !== false) return null
	return (
		<FunctionField
			{...props}
			render={() => fieldTranslations[field][sourceValue]}
		/>
	)
}

export default RadioButtonField
