import React from 'react'
import { Labeled } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import LocationIcon from '@material-ui/icons/LocationCity'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import { Typography } from './overrides'

const ResultField = ({ label, localAmount, remoteAmount }) => {
	const classes = useStyles()

	return (
		<div>
			<Labeled label={label}>
				<div className={classes.resultField}>
					<div className={classes.resultLocal}>
						<LocationIcon
							className={classes.icon}
							color="primary"
							fontSize="small"
						/>
						<Typography variant="body2">{localAmount}</Typography>
					</div>
					<div className={classes.resultRemote}>
						<DesktopWindowsIcon
							className={classes.icon}
							color="secondary"
							fontSize="small"
						/>
						<Typography variant="body2">{remoteAmount}</Typography>
					</div>
				</div>
			</Labeled>
		</div>
	)
}

export default ResultField

const useStyles = makeStyles({
	resultField: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	resultLocal: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	resultRemote: {
		marginLeft: 10,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	typoGraphy: {
		marginLeft: 5,
	},
	icon: {
		marginRight: 5,
	},
})
