import { required, email, number, maxLength, minLength } from 'react-admin'

const creditsInOrder = (value) => {
	if (value === 'NO') {
		return 'Buukkaus voidaan peruuttaa, koska luottotiedot eivät ole kunnossa.'
	} else return null
}

const booleanInput = (value) => {
	if (value !== true) {
		return 'Tarkasta luottotiedot jatkaaksesi.'
	}
}

const validatePassword = [
	minLength(8, 'Salasanan tulee olla vähintään 8 merkkiä pitkä'),
]

const validateBooleanInput = [required(''), booleanInput]

const validateRadioButton = required('Valitse yksi vaihtoehdoista.')
const validateCreditsInOrder = [
	required('Valitse yksi vaihtoehdoista.'),
	creditsInOrder,
]
const validateCheckBoxGroupSingle = required('Merkintä puuttuu.')
const validateName = [
	required('Anna asiakkaan nimi.'),
	maxLength(255, 'Nimi ylittää 255 merkin rajan.'),
]
const validateDateTime = required('Anna sovittu aika.')
const validateZone = required('Valitse alue.')
const validateLocation = required('Valitse vähintään yksi tapaamispaikka.')
const validateBirth = required('Valitse asiakkaan syntymäaika.')
const validateAddress = [
	required('Anna asiakkaan osoite.'),
	maxLength(255, 'Osoite ylittää 255 merkin rajan.'),
]
const validatePhoneNumber = [
	required('Anna asiakkaan puhelinnumero.'),
	maxLength(25, 'Numero ylittää 25 merkin rajan.'),
]
const validateInsuredCount = required('Valitse vakuutettavat henkilöt.')
const validateEmail = [
	email('Syötteen pitää olla sähköpostin muodossa.'),
	maxLength(255, 'Sähköposti ylittää 255 merkin rajan.'),
]
const validateText = [
	required('Täytä tämä kenttä.'),
	maxLength(1000, 'Kenttä ylittää 1000 merkin rajan.'),
]
const validateRole = required('Valitse käyttäjän rooli.')
const validateNumber = [
	required('Puuttuva luku'),
	number('Syötteen pitää olla luku.'),
]

export {
	validateRadioButton,
	validateName,
	validateDateTime,
	validateZone,
	validateLocation,
	validatePassword,
	validateBirth,
	validateAddress,
	validatePhoneNumber,
	validateInsuredCount,
	validateEmail,
	validateText,
	validateRole,
	validateNumber,
	validateCheckBoxGroupSingle,
	validateCreditsInOrder,
	validateBooleanInput,
}
