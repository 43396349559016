import * as React from 'react'
import TextField from '@material-ui/core/TextField'
import { useInput, required } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
	header: {
		marginBottom: 10,
	},
	dayTimeFields: {
		display: 'flex',
		alignItems: 'center',
	},
	timePickerField: {
		margin: 0,
		maxWidth: 150,
	},
	line: {
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 21,
	},
})

const TimePickerField = (props) => {
	const {
		input: { name, onChange },
		meta: { touched, error },
		isRequired,
	} = useInput(props)
	return (
		<TextField
			{...props}
			name={name}
			onChange={(stuff) => {
				onChange(stuff)
			}}
			type="time"
			size="small"
			InputLabelProps={{ shrink: true }}
			inputProps={{ step: 300 }}
			error={!!(touched && error)}
			helperText={touched && error ? error : ' '}
			required={isRequired}
			variant="outlined"
		/>
	)
}

const DayTimeFields = ({ record, day, text, req }) => {
	let defaultOpen
	let defaultClose
	if (day !== 'sat' && day !== 'sun') {
		defaultOpen = '09:00'
		defaultClose = '17:00'
	} else {
		defaultOpen = ''
		defaultClose = ''
	}
	const classes = useStyles()
	return (
		<span>
			<span>
				<Typography variant="body2">{text}</Typography>
			</span>
			<span className={classes.dayTimeFields}>
				<TimePickerField
					source={day + '.opensAt'}
					className={classes.timePickerField}
					validate={req ? [required()] : null}
					defaultValue={
						(record && record.businessHours?.[day][0]) || defaultOpen
					}
				/>
				<Typography className={classes.line}>-</Typography>
				<TimePickerField
					source={day + '.closesAt'}
					className={classes.timePickerField}
					validate={req ? [required()] : null}
					defaultValue={
						(record && record.businessHours?.[day][1]) || defaultClose
					}
				/>
			</span>
		</span>
	)
}

const BusinessHoursInput = ({ record }) => {
	const classes = useStyles()
	return (
		<span>
			<Typography className={classes.header}>Aukioloajat</Typography>
			<DayTimeFields record={record} day="mon" text="Ma" req />
			<DayTimeFields record={record} day="tue" text="Ti" req />
			<DayTimeFields record={record} day="wed" text="Ke" req />
			<DayTimeFields record={record} day="thu" text="To" req />
			<DayTimeFields record={record} day="fri" text="Pe" req />
			<DayTimeFields record={record} day="sat" text="La" />
			<DayTimeFields record={record} day="sun" text="Su" />
		</span>
	)
}

export default BusinessHoursInput
