import * as React from 'react'
import { Datagrid, TextField, ReferenceField, Filter } from 'react-admin'
import { TextInput, List } from '../components/overrides'
import { ListActions } from '../components/overrides'

const LocationList = (props) => {
	return (
		<List
			{...props}
			title="Tapaamispaikat"
			bulkActionButtons={false}
			actions={<ListActions />}
			filters={
				<Filter>
					<TextInput label="Etsi nimellä" source="name" alwaysOn />
				</Filter>
			}
		>
			<Datagrid rowClick="show">
				<TextField label="Nimi" source="name" />
				<ReferenceField
					label="Alue"
					source="zoneId"
					reference="zones"
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>
			</Datagrid>
		</List>
	)
}

export default LocationList
