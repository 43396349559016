import * as React from 'react'
import {
	TextInput,
	SimpleForm,
	ReferenceInput,
	SelectInput,
} from '../components/overrides'
import BusinessHoursInput from './BusinessHoursInput'
import { validateZone, validateText } from '../utils/FormValidators'

const LocationForm = ({ title, editView, ...props }) => {
	return (
		<SimpleForm title={title} {...props}>
			<TextInput label="Nimi" source="name" validate={validateText} />
			<ReferenceInput
				label="Alue"
				source="zoneId"
				reference="zones"
				validate={validateZone}
				disabled={editView}
				perPage={100}
			>
				<SelectInput sortAlphabetically />
			</ReferenceInput>
			<BusinessHoursInput />
		</SimpleForm>
	)
}

export default LocationForm
