import * as React from 'react'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, useSafeSetState, useNotify } from 'ra-core'
import Axios from 'axios'
import SmsForm from './SmsForm'
import { Input } from './LoginInput'
import VerificationForm from './VerificationForm'

const LoginForm = (props) => {
	const [loading, setLoading] = useSafeSetState(false)
	const translate = useTranslate()
	const classes = useStyles(props)
	const [loginState, setLoginState] = React.useState('usernamepassword')
	const [sessionId, setSessionId] = React.useState('')
	const notify = useNotify()

	/* 
		1. First login ->  save the userInformation into state
		2. If userInformation.phoneNumber === "empty".
				- Save the phoneNumber into state and save it in the user object in database
				- Send the new phoneNumber from the input into the /sms endpoint.
				- /verify with the phoneNumber state
		3. If userInformation.phoneNumber does exist
				- Take the phoneNumber from login (userInformation.phoneNumber) 
				- Use it to send the /sms
				- Verify normally with /verify
	*/

	const submit = async (values) => {
		try {

			const { data } = await Axios.post(
				`${process.env.REACT_APP_API_URL}/users/login`,
				{
					user: {
						username: values.username,
						password: values.password,
					},
				},
			)

			setSessionId(data.sessionId);
			if (data.hasPhone) {
				setLoginState('verification-code')
			} else {
				setLoginState('sms')
			}
		} catch (e) {
			setLoading(false)
			notify('Käyttäjänimi tai salasana on väärin!', { type: 'error' })
		}

	}

	const validate = (values) => {
		const errors = {
			username: undefined,
			password: undefined,
		}

		if (!values.username) {
			errors.username = translate('ra.validation.required')
		}
		if (!values.password) {
			errors.password = translate('ra.validation.required')
		}
		return errors
	}

	if (loginState === 'sms') {
		return (
			<SmsForm
				props={props}
				setLoginState={setLoginState}
				loginState={loginState}
				sessionId={sessionId}
			/>
		)
	}

	if (loginState === 'verification-code') {
		return (
			<VerificationForm
				props={props}
				loginState={loginState}
				sessionId={sessionId}
			/>
		)
	}

	return (
		<Form
			onSubmit={submit}
			validate={validate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.form}>
						<div className={classes.input}>
							<Field
								autoFocus
								id="username"
								name="username"
								component={Input}
								label={translate('ra.auth.username')}
								disabled={loading}
							/>
						</div>
						<div className={classes.input}>
							<Field
								id="password"
								name="password"
								component={Input}
								label={translate('ra.auth.password')}
								type="password"
								autoComplete="current-password"
								disabled={loading}
							/>
						</div>
					</div>
					<CardActions>
						<Button
							variant="contained"
							type="submit"
							color="primary"
							className={classes.button}
						>
							{loading && (
								<CircularProgress
									className={classes.icon}
									size={18}
									thickness={2}
								/>
							)}
							{translate('ra.auth.sign_in')}
						</Button>
					</CardActions>
				</form>
			)}
		/>
	)
}

const useStyles = makeStyles(
	(theme) => ({
		form: {
			padding: '0 1em 1em 1em',
		},
		input: {
			marginTop: '1em',
		},
		button: {
			width: '100%',
		},
		icon: {
			marginRight: theme.spacing(1),
		},
	}),
	{ name: 'RaLoginForm' },
)

export default LoginForm
