import React from 'react'
import { EditButton } from 'react-admin'
import { Button, Card, Stepper, Step, StepLabel } from '@material-ui/core'
import { Close, Archive } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Loading from '../components/Loading'

const BookingShowActions = (props) => {
	const classes = useStyles()
	const { data, onDialogSelect } = props

	if (!data) {
		return <Loading />
	}
	const { appointmentStartsAt, creditsChecked, status } = data

	const ProcessButton = (props) => (
		<Button
			onClick={() => onDialogSelect(props.dialogName)}
			className={classes.button}
			appointmentStartsAt={appointmentStartsAt}
			creditsChecked={creditsChecked}
			confirmed={
				status === 'CONFIRMED' || status === 'COMPLETED' ? true : false
			}
			disabled={getCurrentStep(data) < props.step}
			{...props}
		/>
	)

	if (!appointmentStartsAt && status !== 'CANCELED') {
		return (
			<Card className={classes.buttons}>
				<ProcessButton
					dialogName="meetingTime"
					className={`${classes.unhandled} ${classes.button}`}
				>
					Valitse uusi tapaamisaika
				</ProcessButton>

				<ProcessButton
					startIcon={<Close />}
					className={classes.cancelButton}
					dialogName="cancel"
				>
					Peruuta
				</ProcessButton>
			</Card>
		)
	}

	return (
		<Card className={classes.buttons}>
			<span className={classes.buttonsSpan}>
				{status !== 'CANCELED_RETRIAL' && status !== 'CANCELED' && (
					<>
						<Stepper
							activeStep={getCurrentStep(data)}
							className={classes.stepper}
							connector={<div />}
						>
							<ProcessStep>
								<ProcessButton dialogName="credits" step={0}>
									Tarkasta asiakastiedot
								</ProcessButton>
							</ProcessStep>

							<ProcessStep>
								<ProcessButton dialogName="agent" step={1}>
									Valitse edustaja
								</ProcessButton>
							</ProcessStep>

							<ProcessStep>
								<ProcessButton dialogName="confirm" step={2}>
									Vahvista buukkaus
								</ProcessButton>
							</ProcessStep>
						</Stepper>
					</>
				)}
				{status === 'CANCELED' && (
					<>
						<ProcessButton
							dialogName="retrial"
							className={classes.retrialButton}
						>
							Palauta käsittelyyn
						</ProcessButton>

						<div style={{ margin: 8 }} />

						<ProcessButton
							dialogName="confirm"
							className={classes.cancelButton}
						>
							Muuta takaisin vahvistetuksi
						</ProcessButton>
					</>
				)}
			</span>
			<div>
				{(status === 'CANCELED' || status === 'CANCELED_RETRIAL') && (
					<Button
						startIcon={<Archive />}
						className={classes.archiveButton}
						onClick={() => onDialogSelect('archive')}
					>
						Arkistoi
					</Button>
				)}
				{status !== 'CANCELED_RETRIAL' &&
					status !== 'CANCELED' &&
					status !== 'COMPLETED' && (
						<ProcessButton
							startIcon={<Close />}
							className={classes.cancelButton}
							dialogName="cancel"
						>
							Peruuta
						</ProcessButton>
					)}

				<EditButton basePath={props.basePath} record={props.data} className={classes.editButton} />
			</div>
		</Card>
	)
}

const ProcessStep = (props) => {
	const classes = useStyles()
	return (
		<Step
			classes={{
				root: props.disabled
					? classes.disabled
					: props.active
						? classes.unhandled
						: classes.step,
				completed: classes.handled,
			}}
			{...props}
		>
			<StepLabel
				StepIconProps={{
					classes: {
						root: classes.defColor,
						completed: classes.handled,
						active: classes.unhandled,
					},
				}}
			>
				{props.children}
			</StepLabel>
		</Step>
	)
}

const getCurrentStep = (data) => {
	return data.creditsChecked !== 'UNCHECKED'
		? data.agentId
			? data.status === 'CONFIRMED' || data.status === 'COMPLETED'
				? 3
				: 2
			: 1
		: 0
}

export default BookingShowActions

const useStyles = makeStyles({
	buttons: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0rem',
		margin: '1rem 0',
	},
	buttonsSpan: {
		display: 'flex',
	},
	button: {
		borderRadius: 'unset',
		padding: '.75rem 2rem',
		color: 'black',
		fontWeight: 'bold',
	},
	divider: {
		display: 'inline-flex',
		margin: '0rem 1rem',
		backgroundColor: 'black',
	},
	defColor: {
		color: '#c3c3c3',
	},
	handled: {
		backgroundColor: '#9affae',
		fontSize: '16px',
		'& $handled': {
			color: 'darkgreen',
		},
	},
	unhandled: {
		backgroundColor: '#e6f48a',
		'& $unhandled': {
			color: '#e6f48a',
			fontWeight: 'bold',
		},
	},

	disabled: {
		backgroundColor: '#c3c3c3',
		fontWeight: 'bold',
	},
	creditButton: {
		marginLeft: '0rem',
	},
	retrialButton: {
		backgroundColor: '#99bbff',
	},
	cancelButton: {
		color: '#ff98a3',
		maxHeight: 48,
	},
	editButton: {
		margin: '5px 1rem 5px 0px',
	},
	archiveButton: {
		color: '#888',
	},
	stepper: {
		width: 900,
		padding: 0,
	},
})
