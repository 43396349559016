import { useUpdate } from 'react-admin'

const SetBookingAgent = (record, id) => {
	const [choose, { loading }] = useUpdate(
		'bookings',
		record.id,
		{ ...record, agentId: id },
		record,
	)
	return [choose, loading]
}

export default SetBookingAgent
