import React from 'react'
import { ReferenceManyField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	referenceManyField: {
		width: '100%',
	},
})

export default (props) => {
	const classes = useStyles()

	return <ReferenceManyField {...props} className={classes.referenceManyField} />
}
