import React from "react";
import { Toolbar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  toolbar: {
    backgroundColor: "unset",
  },
});

export default (props) => {
  const classes = useStyles();

  return <Toolbar {...props} className={classes.toolbar} />;
};
