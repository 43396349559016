import React from 'react'
import { TextInput, SimpleForm, Typography } from '../components/overrides'
import { validateText } from '../utils/FormValidators'

export default ({ title, ...props }) => (
	<SimpleForm title={title} {...props}>
		<TextInput label="Nimi" source="name" validate={validateText} />
		<Typography variant="subtitle1">Tapaamistyyppien huomiot</Typography>
		<TextInput label="Konttoritapaamiset" source="locationConsiderations" />
		<TextInput label="Puhelintapaamiset" source="phoneConsiderations" />
		<TextInput label="Kotikäynnit" source="homeConsiderations" />
		<TextInput label="Yritystapaamiset" source="companyConsiderations" />
		<TextInput label="Verkkotapaamiset" source="webConsiderations" />
		<TextInput label="Maatilatapaamiset" source="farmConsiderations" />
	</SimpleForm>
)
