import React from 'react'
import { SaveButton, Toolbar } from 'react-admin'
import {
	SimpleForm,
	Edit,
	TextInput,
	PasswordInput,
	SelectArrayInput,
} from '../components/overrides'

const ProfileEditToolbar = (props) => {
	return (
		<Toolbar {...props}>
			<SaveButton />
		</Toolbar>
	)
}

const ProfileEdit = (props) => {
	const id = JSON.parse(localStorage.getItem('user')).id
	return (
		<Edit title="Käyttäjätiedot" basePath="/me" resource="me" id={id}>
			<SimpleForm toolbar={<ProfileEditToolbar />} title="Käyttäjätiedot">
				<TextInput label="Käyttäjätunnus" source="username" disabled />
				<TextInput label="Etunimi" source="firstName" />
				<TextInput label="Sukunimi" source="lastName" />
				<TextInput label="Sähköposti" source="email" />
				<label>
					Jos haluat vaihtaa puhelinnumeron. Poista se ja paina tallenna ja
					kirjaudu uudelleen sisään.
				</label>
				<TextInput label="Puhelinnumero" source="phoneNumber" resettable />

				<PasswordInput
					label="Salasana"
					source="password"
					autoComplete="new-password"
				/>
				<SelectArrayInput
					label="Rooli"
					disabled
					source="roles"
					optionText="name"
					choices={[
						{ id: 'ADMIN', name: 'ADMIN' },
						{ id: 'COORDINATOR', name: 'COORDINATOR' },
						{ id: 'SALES', name: 'SALES' },
					]}
				/>
			</SimpleForm>
		</Edit>
	)
}

export default {
	edit: ProfileEdit,
}
