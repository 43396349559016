import React from 'react'
import { Typography } from '@material-ui/core'
import BoxOut from '../BoxOut'

export default (props) => {
	return (
		<BoxOut>
			<Typography variant={props.variant}> {props.children} </Typography>
		</BoxOut>
	)
}
